/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

import { _n } from 'utils';

const Meta = ( { eliminated, category_slug: categorySlug, category, advancing, checkpoint } ) => (
	<p className="round-meta round-meta__trivia">
		<span>{ advancing } advancing</span>
		{ parseInt( eliminated.replace( ',', '' ) ) < 0 ? ( <span>? eliminated</span> ) : ( <span>{ eliminated } eliminated</span> ) }
		{
			checkpoint && (
				<span> { checkpoint.num_winners_formatted } { _n( 'person', 'people', checkpoint.num_winners ) } left with { checkpoint.prize_formatted } at the checkpoint</span> )
		}

		<span>
			<a href={ `/${ hqbuff.currentCountry }/category/${ categorySlug }` }><u>{ category }</u></a>
		</span>
	</p>
);

export default Meta;
