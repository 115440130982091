/**
 * External dependencies.
 */
import React, { Component } from 'react';
import moment from 'moment';

class Year extends Component {
	constructor( props ) {
		super( props );

		const { year, currentGame } = this.props;

		if ( ! currentGame ) {
			this.state = {
				isExpanded: year === moment().year(),
			};
		} else {
			this.state = {
				isExpanded: year === moment( currentGame.start_time ).year(),
			};
		}

		this.toggle = this.toggle.bind( this );
		this.onKeyPress = this.onKeyPress.bind( this );
	}

	onKeyPress( e ) {
		if ( e.key === 'Enter' ) {
			this.toggle();
		}
	}

	toggle() {
		const { isExpanded } = this.state;

		this.setState( {
			isExpanded: ! isExpanded,
		} );
	}

	render() {
		const { year, children } = this.props;
		const { isExpanded } = this.state;

		return (
			<li>
				<strong
					tabIndex="0"
					onClick={ this.toggle }
					onKeyPress={ this.onKeyPress }
					role="button"
					className={ isExpanded ? 'active' : null }
				>
					{ year }
				</strong>

				{ isExpanded && ( <ul>{ children }</ul> ) }
			</li>
		);
	}
}

export default Year;
