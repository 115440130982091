/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import './country-dropdown.js';

import Branding from './branding.js';
import Nav from './nav.js';

const Masthead = () => (
	<div className="masthead">
		<Branding />
		<Nav />
	</div>
);

export default Masthead;
