/**
 * External dependencies.
 */
import React from 'react';
import { last } from 'lodash';

/**
 * Internal dependencies.
 */
import ParticipatingCountries from 'app/components/game-participating-countries';

import { _n } from 'utils';

const Summary = ( props ) => {
	const { winner_count: winnerCount, currency, average_prize: prize } = props.summary;
	const unformattedWinnerCount = parseFloat( winnerCount.replace( /,/g, '' ) );

	return props.practice ? ( `` ) : (
		<div id="game-summary" className="game-summary">
			<div className="trivia-winners">
				{ winnerCount } { _n( 'person', 'people', unformattedWinnerCount ) } won around <span className="prize">{ currency + prize }</span>{ _n( '!', ' each!', unformattedWinnerCount ) }
			</div>

			<div className="game__participating-countries">
				<span className="text-muted">Participating countries:</span>
				<ParticipatingCountries countries={ props.countries } />
			</div>
		</div>
	);
};

export default Summary;
