/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Widget from 'app/components/widget';
import Badge from 'app/components/badge';

class CategoryList extends Component {
	constructor( props ) {
		super();

		this.state = {
			categories: null,
		};
	}

	componentDidMount() {
		const { currentCountry } = hqbuff;

		fetch( `/api/${ currentCountry }/category/trivia?limit=15`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				this.setState( {
					categories: data.items,
				} );
			} );
	}

	render() {
		const { currentCountry } = hqbuff;
		const { categories } = this.state;
		const title = 'Categories';
		const inlineStyle = {
			color: '#51ca94',
		};

		if ( ! categories ) {
			return <Widget title={ title }>Loading...</Widget>;
		}

		return (
			<Widget title={ title } className="widget--cloud">
				{ categories.map( ( term ) => (
					<li key={ term.slug }>
						<Badge
							href={ `/${ currentCountry }/category/${ term.slug }` }
							level="tertiary"
						>
							{ term.name }
						</Badge>
					</li>
				) ) }
				<a href={ `/${ currentCountry }/game/trivia/categories` } className="widget__more" style={ inlineStyle }><strong>View More &rarr;</strong></a>
			</Widget>
		);
	}
}

export default CategoryList;
