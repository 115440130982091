/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Answer from './answer.js';

class Answers extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			savage: props.savage,
			answers: props.answers,
			showingAnswer: false,
			pickedAnswer: undefined,
			pickedCorrectly: false,
		};

		this.toggleAnswer = this.toggleAnswer.bind( this );
	}

	toggleAnswer( id, correct ) {
		const { answers, showingAnswer } = this.state;

		if ( showingAnswer ) {
			return;
		}

		this.setState( {
			showingAnswer: true,
			pickedAnswer: id,
			pickedCorrectly: answers.find( ( a ) => {
				return a.id == id;
			} ).correct,
		} );
	}

	render() {
		const { savage, showingAnswer, answers, pickedAnswer, pickedCorrectly } = this.state;

		return (
			<div className="trivia-answer-list">
				{ answers.map( ( answerData, i ) => (
					<Answer
						savage={ savage }
						key={ answerData.id }
						showingAnswer={ showingAnswer }
						toggleFunc={ this.toggleAnswer }
						pickedAnswer={ pickedAnswer }
						pickedCorrectly={ pickedCorrectly }
						{ ...answerData }
					/>
				) ) }
			</div>
		);
	}
}

export default Answers;
