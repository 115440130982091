/**
 * External dependencies.
 */
import React, { Component } from 'react';
import moment from 'moment';

class Month extends Component {
	constructor( props ) {
		super( props );

		const { month, currentGame } = this.props;

		if ( ! currentGame ) {
			this.state = {
				isExpanded: month === moment().month(),
			};
		} else {
			this.state = {
				isExpanded: month === moment( currentGame.start_time ).month(),
			};
		}

		this.toggle = this.toggle.bind( this );
		this.onKeyPress = this.onKeyPress.bind( this );
	}

	onKeyPress( e ) {
		if ( e.key === 'Enter' ) {
			this.toggle();
		}
	}

	toggle() {
		const { isExpanded } = this.state;

		this.setState( {
			isExpanded: ! isExpanded,
		} );
	}

	render() {
		const { month, children } = this.props;
		const { isExpanded } = this.state;

		return (
			<li>
				<strong
					tabIndex="0"
					onClick={ this.toggle }
					onKeyPress={ this.onKeyPress }
					role="button"
					className={ isExpanded ? 'active' : null }
				>
					{ moment().month( month ).format( 'MMMM' ) }
				</strong>

				{ isExpanded && ( <ul>{ children }</ul> ) }
			</li>
		);
	}
}

export default Month;
