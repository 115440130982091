/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Fragment } from 'react';

/**
 * Internal dependencies.
 */
import { SingleRoundPlaceholder } from 'app/components/placeholders';
import Pagination from 'app/components/archive-pagination';

const RoundList = ( props ) => {
	const { rounds, hasPagination, pagination, hasFatalError } = props;

	return (
		<Fragment>
			<div className="round-list">
				{ ! rounds && (
					<SingleRoundPlaceholder />
				) }

				{ rounds && rounds.length === 0 && (
					<div className="round">No relevant results found. { hasFatalError ? 'This is likely because something awful happened to our poor server.' : null }</div>
				) }

				{ rounds && rounds.length > 0 && (
					rounds.map( ( round, i ) => (
						<props.component key={ i } { ...round } />
					) )
				) }
			</div>

			{ hasPagination && (
				<Pagination { ...pagination } />
			) }
		</Fragment>
	);
};

export default RoundList;
