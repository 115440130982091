/**
 * Determine if a value exists in an array.
 *
 * @param {mixed} needle Thing to look for.
 * @param {Array} haystack Thing to look in.
 * @return {boolean} In array.
 */
export const inArray = ( needle, haystack ) => {
	return haystack.indexOf( needle ) !== -1;
};

/**
 * Mobile width?
 *
 * @return {boolean} Is mobile?
 */
export const isMobile = () => {
	const windowWidth = window.innerWidth;

	return 576 >= windowWidth;
};

/**
 * Find an ancestor node with a specified class.
 *
 * @param {Object} el DOM object.
 * @param {string} className Class name to search for.
 * @return {null|Object} DOM object if found.
 */
export const findAncestor = function( el, className ) {
	while ( ( el = el.parentElement ) && ! el.classList.contains( className ) ) { }

	return el;
};

/**
 * Toggle the display of an element.
 *
 * @param {Object} el DOM object.
 * @param {string} displayType Display type to use.
 * @return {void}
 */
export const toggle = function( el, displayType = 'block' ) {
	if ( 'none' === el.style.display || ! el.style.display ) {
		el.style.display = displayType;
	} else {
		el.style.display = 'none';
	}
};
