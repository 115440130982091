/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';

const Widget = ( props ) => {
	const { title, className, children } = props;

	const widgetClassName = classnames(
		className,
		{
			widget: true,
		}
	);

	return (
		<div className={ widgetClassName }>
			<h4 className="widget__title">{ title }</h4>
			<ul className="list">{ children }</ul>
		</div>
	);
};

export default Widget;
