/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';
import { humanDate } from 'utils';
import { getPageNumberAppender, capitalizeWord } from 'utils';

import Secondary from 'app/components/layout/secondary';
import RoundList from 'app/components/round-list';

const Game = ( props ) => {
	const { direct_link: directLink, start_time: startTime } = props;

	return (
		<div className="round">
			<a href={ directLink }>{ humanDate( startTime ) }</a>
		</div>
	);
};

const GameArchiveList = ( props ) => {
	const { items: games, pagination: { current: currentPage } } = props;
	const { tagName, country: { shortName: currentCountry }, currentGameType } = hqbuff;

	return (
		<div className="container site">
			<div className="split primary-content">

				<main role="main" className="games split__1">
					<h1 className="page-title">
						<span className="screen-reader-text">{ currentCountry.toUpperCase() } </span>HQ { capitalizeWord( currentGameType ) } Games
						{ getPageNumberAppender( currentPage ) }
					</h1>
					<RoundList
						rounds={ games }
						hasPagination
						component={ Game }
						{ ...props }
					/>
				</main>

				<Secondary { ...props } />

			</div>
		</div>
	);
};

export default withLayout( GameArchiveList );
