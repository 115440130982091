/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Badge from 'app/components/badge';

class Answer extends Component {
	constructor( props ) {
		super( props );
	}

	render() {
		const { text, toggleFunc, display_count: displayCount, show, showingAnswer } = this.props;

		return (
			<li onClick={ toggleFunc } className="trivia-answer">
				<span>{ text }</span>

				{ showingAnswer && (
					<span className="trivia-answer__count">{ displayCount }%</span>
				) }
			</li>
		);
	}
}

export default Answer;
