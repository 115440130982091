/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import CountryDropdown from './country-dropdown.js';
import 'app/components/dropdown-menu';

const Nav = () => {
	const countryShortName = hqbuff.country.shortName;

	return (
		<div className="masthead-nav">
			<CountryDropdown />

			<div id="masthead-practice-dropdown" className="masthead-nav__country dropdown-container">
				<button id="practice-toggle" className="masthead-nav__toggle dropdown-toggle" data-nav="masthead-practice-list">
					Practice
				</button>
				<nav id="masthead-practice-list" className="masthead-nav__dropdown dropdown dropdown--right">
					<ul>
						<li><a href={ `/${ countryShortName }/practice/trivia` }>Trivia</a></li>
						<li><a href={ `/${ countryShortName }/practice/words` }>Words</a></li>
						<li><a href={ `/${ countryShortName }/practice/tunes` }>Tunes</a></li>
					</ul>
				</nav>
			</div>

			<div className="masthead-nav__calendar dropdown-container">
				<button id="menu-toggle" className="menu-toggle masthead-nav__toggle dropdown-toggle" data-nav="masthead-menu">
					<img src="/images/menu.svg" />
					<span className="screen-reader-text">Menu</span>
				</button>
				<nav id="masthead-menu" className="masthead-nav__dropdown dropdown dropdown--right">
					<ul>
						<li><a href="/stats">Stats</a></li>
						<li><a href={ `/${ countryShortName }/savage-questions` }>Most Savage { countryShortName.toUpperCase() } Questions</a></li>
						<li><a href={ `/${ countryShortName }/highest-payouts` }>Highest { countryShortName.toUpperCase() } Payouts</a></li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default Nav;
