/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ShowAnswerButton from 'app/components/game-types/trivia/show-answer-button';

const Title = ( props ) => {
	const { direct_link: directLink, question_number: questionNumber, __unstableHideQuestionNumber } = props;

	return (
		<div className="round-title">
			{ ! __unstableHideQuestionNumber && (
				<a href={ directLink } className="round-title__number">{ questionNumber }</a>
			) }
			<ShowAnswerButton { ...props } />
		</div>
	);
};

export default Title;
