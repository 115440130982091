/**
 * Calculate percent eliminated.
 *
 * @param {string} eliminated Number (formatted) eliminated.
 * @param {string} advancing Number (formatted) advancing.
 * @return {string} Percent eliminated.
 */
export const getPercentEliminated = ( eliminated, advancing ) => {
	const eliminatedNumber = parseFloat( eliminated );
	const advancingNumber = parseFloat( advancing );

	const percentEliminated = ( eliminatedNumber / ( advancingNumber + eliminatedNumber ) ) * 100;

	return `${ percentEliminated.toFixed( 0 ) }%`;
};
