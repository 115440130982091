/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import SingleRound from 'app/layouts/single-round';
import NotFound from 'app/layouts/not-found.js';

// Collection of games in a day
class SingleRoundContainer extends Component {
	constructor( props ) {
		super( props );

		const { singleRound } = hqbuff;

		this.state = {
			singleRound,
		};
	}

	render() {
		const { singleRound } = hqbuff;

		if ( singleRound === null || singleRound === undefined ) {
			return <NotFound />;
		}

		return <SingleRound { ...this.state } />;
	}
}

export default SingleRoundContainer;
