/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Year from './year.js';
import Month from './month.js';
import Day from './day.js';

import { getArchiveDateList } from './utils.js';

const ArchiveList = ( props ) => {
	const { currentCountryDateRange } = hqbuff;
	const { min, max } = currentCountryDateRange;

	const dates = getArchiveDateList( new Date( min ), new Date( max ) );

	const archive = dates.map( ( months, year ) => (
		<Year key={ year } year={ year } { ...props }>
			{ months.map( ( days, month ) => (
				<Month key={ year + month } month={ month } { ...props }>
					{ days.map( ( day ) => (
						<Day day={ day } key={ day.toString() } />
					) ) }
				</Month>
			) ) }
		</Year>
	) );

	return (
		<div className="widget">
			<h4 className="widget__title">More Games</h4>
			<ul id="archive-list" className="list list--archive">
				{ archive }
			</ul>
		</div>
	);
};

export default ArchiveList;
