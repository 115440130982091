/**
 * Internal dependencies.
 */
import StatsContainer from 'app/containers/stats.js';

const routes = [];

routes.push( {
	path: '/stats',
	component: StatsContainer,
} );

export default routes;
