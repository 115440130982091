/**
 * Internal dependencies.
 */
import CategoryArchiveListContainer from 'app/containers/category-archive-list.js';
import { countryList } from 'utils';

const routes = [];

countryList.map( ( country ) => {
	routes.push( {
		path: `/${ country.short_name }/category/:category`,
		component: CategoryArchiveListContainer,
	} );
} );

export default routes;
