/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import { getQueryArg } from 'utils';

const withPagination = ( WrappedComponent, basePath ) => {
	return class extends Component {
		constructor( props ) {
			super( props );

			this.state = {
				items: false,
				noResults: false,
				pagination: {},
			};
		}

		componentDidMount() {
			const page = getQueryArg( window.location.href, 'page' ) || 1;

			fetch( `/api/${ hqbuff.currentCountry }/${ basePath }?page=${ page }`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			} )
				.then( ( response ) => response.json() )
				.then( ( data ) => {
					const { items, pagination } = data;

					this.setState( {
						items,
						pagination,
					} );
				} )
				.catch( () => {
					this.setState( {
						hasFatalError: true,
						items: [],
					} );
				} );
		}

		render() {
			return <WrappedComponent { ...this.state } { ...this.props } />;
		}
	};
};

export default withPagination;
