/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import { countryList, smartContextUrl } from 'utils';

const CountryList = () => {
	const { currentCountry } = hqbuff;

	return (
		<ul>
			{ countryList.map( ( country ) => {
				const { short_name: shortName, full_name: fullName } = country;

				const className = classnames(
					'country-list__country',
					{
						'country-list__country--active': currentCountry === shortName,
					}
				);

				return (
					<li key={ shortName }>
						<a
							href={ `${ smartContextUrl( currentCountry, shortName ) }` }
							className={ className }
						>
							<img className="flag" src={ `/images/flag-${ shortName }.svg` } alt="" />
							{ fullName }
						</a>
					</li>
				);
			} ) }
		</ul>
	);
};

export default CountryList;
