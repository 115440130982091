/**
 * External dependencies
 */
import { parse } from 'qs';

/**
 * Returns a single query argument of the url
 *
 * @param {string} url URL
 * @param {string} arg Query arg name
 *
 * @return {Array|string} Query arg value.
 */
export const getQueryArg = ( url, arg ) => {
	const queryStringIndex = url.indexOf( '?' );
	const query = queryStringIndex !== -1 ? parse( url.substr( queryStringIndex + 1 ) ) : {};

	return query[ arg ];
};
