/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Title from './title.js';
import Meta from './meta.js';

import Answers from 'app/components/game-types/trivia/round/answers';

const PayoutRound = ( props ) => {
	const {
		last_question: text,
		question_number: questionNumber,
	} = props;

	return (
		<div className="round round--trivia" id={ questionNumber }>
			<Title { ...props } />

			<small className="text-muted" style={ { margin: '10px 0 0', display: 'block' } }><strong>Final Question:</strong></small>
			<p style={ { margin: '8px 0 0' } }>{ text }</p>

			<Answers { ...props } />

			<Meta { ...props } />
		</div>
	);
};

export default PayoutRound;
