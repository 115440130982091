/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ShowAnswerButton from 'app/components/game-types/trivia/show-answer-button';

const Title = ( props ) => {
	const { question_number: questionNumber, direct_link: directLink } = props;

	return (
		<div className="round-title">
			<a href={ directLink } className="round-title__number">{ questionNumber }</a>
			<ShowAnswerButton { ...props } />
		</div>
	);
};

export default Title;
