/**
 * External dependencies.
 */
import React, { Component } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Badge from 'app/components/badge';

class Answer extends Component {
	constructor( props ) {
		super( props );
	}

	render() {
		const { text, count, correct, savage, showingAnswer, toggleFunc, percent, id, pickedAnswer, pickedCorrectly } = this.props;

		const picked = pickedAnswer == id;

		const answerBarClass = classnames(
			'trivia-answer',
			{
				// they picked it correctly, or they didnt pick it but it's correct
				// show green if thhey choose correctly, or not
				green: ( picked && pickedCorrectly ) || ( correct && showingAnswer ),

				// It's picked, but wasn't correct. Only show red when they choose wrong
				red: ( picked && ! pickedCorrectly ) && ! correct && showingAnswer,

				// They didn't pick it
				gray: ! picked && ! correct && showingAnswer,
			}
		);

		// Set the percent to 0, unless we are showing the answer
		let actualPercent = 0;
		if ( showingAnswer ) {
			actualPercent = percent;
		}

		// let normalizedPercent = percent > 80
		return (
			<div className={ answerBarClass } onClick={ () => toggleFunc( id, correct ) }>
				<div className="answer-text">{ text }</div>
				{ showingAnswer ? <div className="answer-selection">{ count }</div> : null }
				<div className="answer-bar" style={ { width: actualPercent } }></div>
			</div>
		);
	}
}

export default Answer;
