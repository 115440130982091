/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Register from 'app/layouts/register';
import { loginUser } from 'utils';

class RegisterContainer extends Component {
	constructor() {
		super();

		this.onSubmit = this.onSubmit.bind( this );
		this.onChange = this.onChange.bind( this );

		this.state = {
			username: '',
			password: '',
			errors: [],
		};
	}

	onChange( e ) {
		const { target } = e;

		const field = target.name;
		const value = target.value;

		this.setState( {
			[ field ]: value,
		} );
	}

	onSubmit( e ) {
		e.preventDefault();

		const { username, password } = this.state;

		fetch( '/api/user/register', {
			method: 'POST',
			body: JSON.stringify( {
				username,
				password,
			} ),
			headers: {
				'Content-Type': 'application/json',
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				const { errors, username, jwt } = data;

				if ( errors ) {
					this.setState( {
						errors,
					} );
				} else {
					loginUser( data );
				}
			} );
	}

	render() {
		const { username, password, errors } = this.state;

		return (
			<Register
				onSubmit={ this.onSubmit }
				onChange={ this.onChange }
				username={ username }
				password={ password }
				errors={ errors }
			/>
		);
	}
}

export default RegisterContainer;
