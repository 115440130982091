/**
 * External dependencies.
 */
import React from 'react';

const Countdown = ( props ) => {
	const { isOver, elapsedTime, duration } = props;

	if ( isOver ) {
		return null;
	}

	return (
		<div className="circular-countdown">
			<span className="circular-countdown__time-remaining">{ duration - elapsedTime }</span>
			<svg>
				<circle r="18" cx="20" cy="20" style={ { animationDuration: `${ duration }s` } } />
			</svg>
		</div>
	);
};

export default Countdown;
