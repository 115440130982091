/**
 * External dependencies.
 */
import React, { Component } from 'react';
import { difference, head, uniq, filter, fill } from 'lodash';

/**
 * Internal dependencies.
 */
import Answer from './answer';
import Lives from './lives';
import Title from './title.js';
import Hint from './hint.js';

import MissingRound from 'app/components/game-missing-round';

import {
	ROUND_DURATION,
	LETTER_REVEAL,

	validCharacters,
	getLettersFromWords,
} from 'app/components/game-types/words/utils';

import { inArray, findAncestor } from 'utils';

class Round extends Component {
	/**
	 * Bind component.
	 */
	constructor() {
		super();

		this.state = {
			isPlaying: false,
			isOver: false,
			elapsedTime: 0,
			correctLetters: [],
			incorrectLetters: [],
			revealedLetters: [],
			hasUsedKeyboard: false,
		};

		// Create reference to this DOM node.
		this.ref = React.createRef();

		this.onKeyDown = this.onKeyDown.bind( this );

		this.startRound = this.startRound.bind( this );
		this.refocusRound = this.refocusRound.bind( this );
		this.showAnswer = this.showAnswer.bind( this );

		this.addCorrectLetter = this.addCorrectLetter.bind( this );
		this.addIncorrectLetter = this.addIncorrectLetter.bind( this );

		this.tick = this.tick.bind( this );
	}

	/**
   * Add initially revealed letters to the list of correct letters, and revealed letters.
   *
   * This makes it easier to compare the total number of letters in the answer
   * to the total number of letters on the board at all times.
	 *
	 * @todo a lot of duplicate logic in here.
   */
	componentDidMount() {
		const { initial_letters: initialLetters, answer } = this.props;

		// A seemingly ghetto way of finding the number of tiles a letter exists in the answer.
		const correctLetters = [];
		const allLetters = getLettersFromWords( answer );

		initialLetters.map( ( letter ) => {
			const numberOfTimes = filter( allLetters, ( aLetter ) => aLetter === letter ).length;
			correctLetters.push( ...fill( Array( numberOfTimes ), letter ) );
		} );

		this.setState( {
			...this.state,
			revealedLetters: initialLetters,
			correctLetters,
		} );
	}

	/**
   * React does not mutate the state immediately.
   *
   * Handle some state changes based on updated values here.
   *
   * This feels really weirdly wrong.
   */
	componentDidUpdate() {
		// Do nothing more if game is over.
		if ( this.state.isOver ) {
			return;
		}

		// Check if the incorrect letters is equal to 3 (the number of lives).
		// End game if so.
		if ( this.state.incorrectLetters.length === 3 ) {
			this.setState( {
				isOver: true,
			} );

			clearInterval( this.interval );
		}

		// Check if the correct letters is equal to the total answer letter count.
		// End game if so.
		const { answer } = this.props;

		if ( this.state.correctLetters.length === getLettersFromWords( answer ).length ) {
			this.setState( {
				isOver: true,
			} );

			clearInterval( this.interval );
		}

		// Time is up. Game is over.
		if ( ROUND_DURATION === this.state.elapsedTime ) {
			this.setState( {
				isOver: true,
			} );

			clearInterval( this.interval );
		}
	}

	/**
	 * Log a letter that exists in the answer and has either been revealed, or guessed.
	 *
	 * @param {string} letter Letter key pressed.
	 */
	addCorrectLetter( letter ) {
		const { answer } = this.props;

		// Do not add again if already exists.
		if ( inArray( letter, this.state.correctLetters ) ) {
			return;
		}

		// A seemingly ghetto way of finding the number of tiles a letter exists in the answer.
		const numberOfTimes = filter( getLettersFromWords( answer ), ( aLetter ) => aLetter === letter ).length;

		// Fill the correctLetters list with how many exist.
		this.setState( {
			correctLetters: [
				...this.state.correctLetters,
				...fill( Array( numberOfTimes ), letter ),
			],
		} );
	}

	/**
	 * Log a letter that does not exist in the answer.
	 *
	 * @param {string} letter Letter key pressed.
	 */
	addIncorrectLetter( letter ) {
		this.setState( {
			incorrectLetters: uniq( [
				...this.state.incorrectLetters,
				letter,
			] ),
		} );
	}

	/**
   * Start playing a round.
   *
   * - Focuses the current round in the browser.
   * - Sets the play state to true.
   * - Starts a ticker to make changes every second.
   */
	startRound() {
		this.setState( {
			isPlaying: true,
		} );

		this.ref.current.focus();

		// Ghetto DOM touching.
		const roundEl = findAncestor( this.ref.current, 'round' );
		roundEl.scrollIntoView( {
			behavior: 'smooth',
		} );

		this.interval = setInterval( this.tick, 1000 );
	}

	/**
   * Refocus the input to allow the game to be continued.
   */
	refocusRound() {
		if ( this.state.isPlaying ) {
			this.ref.current.focus();
		}
	}

	/**
   * Show the answer (for dumbos).
   */
	showAnswer() {
		this.setState( {
			isOver: true,
		} );
	}

	/**
	 * Keep track of elapsed time and perform duties over time if needed.
   *
   * @todo split this out in to other functions.
	 */
	tick() {
		const { to_reveal_letters: toRevealLetters } = this.props;
		const { elapsedTime, revealedLetters } = this.state;

		// Tick.
		this.setState( {
			elapsedTime: elapsedTime + 1,
		} );

		// Time has elapsed and there are still letters to reveal.
		// Find remaining letters that haven't been placed on the board.
		const remainingToShow = difference( toRevealLetters, revealedLetters );

		if ( elapsedTime % LETTER_REVEAL === 0 && elapsedTime > ( LETTER_REVEAL - 1 ) && remainingToShow.length > 0 ) {
			const letter = head( remainingToShow );

			// Log as a correct letter.
			this.addCorrectLetter( letter );

			// Add to list of revealedLetters
			this.setState( {
				revealedLetters: [
					...this.state.revealedLetters,
					letter,
				],
			} );
		}
	}

	/**
	 * Monitor for keyboard activity when question is being played.
	 *
	 * @todo watch for multiple keys.
   *
   * @param {Object} e Keydown event.
	 */
	onKeyDown( e ) {
		const { answer } = this.props;
		const { isOver } = this.state;

		// Don't log if game is already over.
		if ( isOver ) {
			return;
		}

		// Uppercase for consistency.
		const letter = e.key.toUpperCase();

		// Not a letter (another key like ESC), do nothing.
		if ( ! inArray( letter, validCharacters ) ) {
			return;
		}

		this.setState( {
			hasUsedKeyboard: true,
		} );

		// Letter exists in the answer.
		if ( inArray( letter, getLettersFromWords( answer ) ) ) {
			this.addCorrectLetter( letter );
		// Log incorrect guess.
		} else {
			this.addIncorrectLetter( letter );
		}
	}

	render() {
		const {
			text: question,
			answer,
			question_number: questionNumber,
			advancing,
			eliminated,
		} = this.props;

		const { incorrectLetters } = this.state;

		if ( '' === question ) {
			return <MissingRound questionNumber={ questionNumber + 1 } />;
		}

		return (
			<div
				id={ questionNumber }
				className="round round--words"
				onClick={ this.refocusRound }
			>
				<Title
					startRound={ this.startRound }
					showAnswer={ this.showAnswer }
					{ ...this.state }
					{ ...this.props }
				/>

				<h3 className="round-hint">{ question }</h3>

				<Answer
					words={ answer }
					{ ...this.state }
					{ ...this.props }
				/>

				<Lives incorrectLetters={ incorrectLetters } />

				<Hint { ...this.props } { ...this.state } />

				{ ( undefined !== advancing || undefined !== eliminated ) && ( 0 !== advancing || 0 !== eliminated ) && (
					<p className="round-meta round-meta__words">
						{ 0 !== advancing && ( <span>{ advancing.toLocaleString( 'en', {
							useGrouping: true,
						} ) } advancing</span> ) }

						{ 0 !== eliminated && ( <span>{ eliminated.toLocaleString( 'en', {
							useGrouping: true,
						} ) } eliminated</span> ) }
					</p>
				) }

				<input
					className="words-input-honeypot"
					type="text"
					tabIndex="-1"
					onKeyDown={ this.onKeyDown }
					ref={ this.ref }
				/>

				<span className="round-focus" />
			</div>
		);
	}
}

export default Round;
