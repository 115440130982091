/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';
import { find } from 'lodash';

/**
 * Internal dependencies.
 */
import Badge from 'app/components/badge';
import Popover from 'app/components/popover';

class ManageTags extends Component {
	constructor() {
		super();

		this.isCurrentTag = this.isCurrentTag.bind( this );
	}

	isCurrentTag( tag ) {
		const { currentTags } = this.props;

		return find( currentTags, [ 'slug', tag.slug ] );
	}

	render() {
		const { availableTags, currentTags, addTag, removeTag, updateCustomTag, addCustomTag, customTag } = this.props;

		if ( availableTags.items.length === 0 ) {
			return null;
		}

		return (
			<div className="game-manage-tags">
				<form className="game-manage-tags__add" onSubmit={ addCustomTag }>
					<input type="text" placeholder="Tag name..." value={ customTag } onChange={ updateCustomTag } />
					<button type="submit">Add</button>
				</form>

				<ul className="game-manage-tags__list">
					{ availableTags.items.map( ( tag ) => {
						const isCurrent = this.isCurrentTag( tag );

						return (
							<li key={ tag.slug }>
								{ tag.name }

								{ isCurrent ? (
									<Badge onClick={ () => removeTag( tag ) } level="danger">-</Badge>
								) : (
									<Badge onClick={ () => addTag( tag ) } level="secondary">+</Badge>
								) }
							</li>
						);
					} ) }
				</ul>
			</div>
		);
	}
}

export default ManageTags;
