/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Game from './game.js';
import Widget from 'app/components/widget';

class UpcomingGames extends Component {
	constructor() {
		super();

		this.state = {
			games: false,
		};
	}

	componentDidMount() {
		fetch( `/api/${ hqbuff.currentCountry }/upcoming`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( games ) => {
				this.setState( {
					games: games,
				} );
			} ).catch( ( err ) => {
				this.setState( {
					games: { shows: [] },
				} );
			} );
	}

	render() {
		const { games } = this.state;
		const title = 'Upcoming Games';

		if ( ! games ) {
			return <Widget title={ title }>Loading...</Widget>;
		}

		const { shows } = games;

		if ( shows.length == 0 ) {
			return <Widget title={ title }>N/A</Widget>;
		}

		const upcomingGames = shows.map( ( show, i ) => (
			<Game { ...show } key={ i } />
		) );

		return (
			<Widget title={ title }>{ upcomingGames }</Widget>
		);
	}
}

export default UpcomingGames;
