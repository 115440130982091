/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Practice from 'app/layouts/practice';
import NotFound from 'app/layouts/not-found';

// Collection of games in a day
const PracticeContainer = ( { match } ) => {
	const { currentGame } = hqbuff;

	if ( 1 !== 1 ) {
		return <NotFound />;
	}

	return <Practice { ...currentGame } />;
};

export default PracticeContainer;
