/* global hqbuff */

/**
 * Internal dependencies.
 */
import { withPagination } from 'app/hoc';
import CategoryArchiveList from 'app/layouts/category-archive-list';
import NotFound from 'app/layouts/not-found.js';

let Page;

if ( hqbuff.categorySlug ) {
	Page = withPagination(
		CategoryArchiveList,
		`category/${ hqbuff.currentGameType }/${ hqbuff.categorySlug }`
	);
} else {
	Page = NotFound;
}

export default Page;
