/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import UpcomingGames from 'app/components/widgets/upcoming-games';
import ArchiveList from 'app/components/widgets/archive-list';
import SavageQuestionList from 'app/components/widgets/savage-question-list';
import CategoryList from 'app/components/widgets/category-list';
import TagList from 'app/components/widgets/tag-list';

const Secondary = ( props ) => (
	<div className="sidebar split__2">
		<UpcomingGames />
		{ hqbuff.currentGameType == 'trivia' ? <CategoryList /> : '' }
		<TagList />
		<SavageQuestionList />
		<ArchiveList { ...props } />
	</div>
);

export default Secondary;
