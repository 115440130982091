/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import { logoutUser } from 'utils';

class LogoutContainer extends Component {
	componentDidMount() {
		logoutUser();
	}

	render() {
		return null;
	}
}

export default LogoutContainer;
