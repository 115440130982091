/**
 * External dependencies.
 */
import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';

/**
 * Internal dependencies.
 */
import GamePagination from 'app/components/game-pagination';
import CountryDropdown from './country-dropdown.js';

class PaginationBar extends Component {
	constructor() {
		super();

		this.ref = React.createRef();
		this.getOffsetStyle = this.getOffsetStyle.bind( this );

		this.state = {
			offsetStyle: {},
		};
	}

	componentDidMount() {
		const { currentGame } = this.props;

		if ( window.outerWidth <= 576 ) {
			return;
		}

		window.addEventListener( 'scroll', () => this.setState( {
			offsetStyle: this.getOffsetStyle(),
		} ) );
	}

	getOffsetStyle() {
		const { pageYOffset } = window;

		if ( pageYOffset >= 54 ) {
			return {
				position: 'fixed',
				top: 0,
				left: 0,
			};
		}
		return {
			position: 'absolute',
			top: '54px',
		};
	}

	render() {
		const { currentDate } = hqbuff;
		const { currentGame } = this.props;
		const { offsetStyle } = this.state;

		const className = classnames(
			'game-pagination-bar',
			{
				[ `game-pagination-bar--${ currentGame.game_type }` ]: true,
			}
		);

		return (
			<div
				ref={ this.ref }
				className={ className }
				style={ offsetStyle }
			>
				<div className="game-pagination-bar-pagination">
					<GamePagination currentGame={ currentGame } />

					<span className="game-pagination-bar-pagination__current">
						{ moment( currentDate ).format( 'MMMM Do, YYYY' ) }
					</span>
				</div>

				<div className="game-pagination-bar__country">
					<CountryDropdown />
				</div>
			</div>
		);
	}
}

export default PaginationBar;
