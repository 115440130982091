/* global hqbuff */

/**
 * External dependencies.
 */
import moment from 'moment';

/**
 * Return the previous day Date object.
 *
 * @param {string|Object} currentDate Current date.
 * @return {Object} Previous day date object.
 */
export const getPreviousDay = ( currentDate ) => ( moment( currentDate ).subtract( 1, 'day' ) );

/**
 * Check if the current date has a previous game date.
 *
 * Use the current countries minimum range.
 *
 * @return {Bool} If there is another day.
 */
export const hasPreviousGameDay = () => {
	const { currentCountryDateRange, currentDate } = hqbuff;
	const { min: minDate } = currentCountryDateRange;

	const previousDay = getPreviousDay( currentDate );

	// Greater than or equal to.
	const after = moment( previousDay ).isAfter( minDate );
	const same = moment( previousDay ).isSame( minDate );

	return same || after;
};

/**
 * Return the next day Date object.
 *
 * @param {string|Object} currentDate Current date.
 * @return {Object} Next day date object.
 */
export const getNextDay = ( currentDate ) => ( moment( currentDate ).add( 1, 'day' ) );

/**
 * Check if the current date has a next game date.
 *
 * Use the current countries maximum range.
 *
 * @return {Bool} If there is another day.
 */
export const hasNextGameDay = () => {
	const { currentCountryDateRange, currentDate } = hqbuff;
	const { max: maxDate } = currentCountryDateRange;

	const nextDay = getNextDay( currentDate );

	// Greater than or equal to.
	const before = moment( nextDay ).isBefore( maxDate );
	const same = moment( nextDay ).isSame( maxDate );

	return same || before;
};
