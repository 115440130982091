/* global hqbuff */

// @todo DRY

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import {
	getPreviousDay,
	hasPreviousGameDay,
	getNextDay,
	hasNextGameDay,
} from './utils.js';

const GamePagination = ( props ) => {
	const { currentGame } = props;
	const { currentCountry, currentDate } = hqbuff;

	if ( ! hasPreviousGameDay() && ! hasNextGameDay() ) {
		return null;
	}

	// @todo idk something better than this
	let nextText = 'Next Day';
	let prevText = 'Previous Day';

	if ( props.nextText ) {
		nextText = props.nextText;
	}

	if ( props.prevText ) {
		prevText = props.prevText;
	}

	return (
		<nav aria-label="More games">
			{ hasPreviousGameDay( currentGame ) && (
				<a
					className="pagination-link--prev"
					href={ `/${ currentCountry }/game/${ getPreviousDay( currentDate ).format( 'YYYY-MM-DD' ) }` }
				>
					<span>{ prevText }</span>
				</a>
			) }

			<a
				className="paginatino-link"
				href={ `/${ currentCountry }/practice/${ currentGame.game_type }` }
			>
				<img src="/images/shuffle.svg" />
				{ currentGame.game_type == 'trivia' ? ( <span>Random Questions</span> ) : <span>Random Puzzles</span> }
			</a>

			{ hasNextGameDay( currentGame ) && (
				<a
					className="pagination-link--next"
					href={ `/${ currentCountry }/game/${ getNextDay( currentDate ).format( 'YYYY-MM-DD' ) }` }
				>
					<span>{ nextText }</span>
				</a>
			) }
		</nav>
	);
};

export default GamePagination;
