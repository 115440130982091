/**
 * Internal dependencies.
 */
import LoginContainer from 'app/containers/login.js';
import RegisterContainer from 'app/containers/register.js';
import LogoutContainer from 'app/containers/logout.js';

const routes = [];

routes.push( {
	path: '/login',
	component: LoginContainer,
} );

routes.push( {
	path: '/register',
	component: RegisterContainer,
} );

routes.push( {
	path: '/logout',
	component: LogoutContainer,
} );

export default routes;
