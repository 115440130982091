/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';

import Secondary from 'app/components/layout/secondary';

import { DefaultRound as TriviaRound } from 'app/components/game-types/trivia/round-output-variations';
import WordRound from 'app/components/game-types/words/round';

const SingleRound = ( props ) => {
	const {
		singleRound,
		singleRound: {
			game_type: gameType,
			game_link: gameLink,
			question_number: questionNumber,
		},
	} = props;

	let round = false;

	if ( gameType !== 'trivia' ) {
		round = <WordRound { ...singleRound } />;
	} else {
		round = (
			<TriviaRound { ...singleRound } __unstableHideQuestionNumber={ true } />
		);
	}

	return (
		<div className="container site">
			<div className="split primary-content">
				<main role="main" className="games split__1">
					<a href={ `${ gameLink }/#${ questionNumber }` } className="page-title">&larr; View Full Game</a>

					<div className="round-list">
						{ round }
					</div>
				</main>

				<Secondary { ...props } />
			</div>
		</div>
	);
};

export default withLayout( SingleRound );
