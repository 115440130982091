/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';

import Secondary from 'app/components/layout/secondary';

import RoundList from 'app/components/round-list';
import { HighestPayoutListRound } from 'app/components/game-types/trivia/round-output-variations';

const TagArchiveList = ( props ) => {
	const { country: { shortName: currentCountry } } = hqbuff;
	const { items: rounds } = props;

	return (
		<div className="container site">
			<div className="split primary-content">

				<main role="main" className="games split__1">
					<h1 className="page-title">
						<span className="screen-reader-text">{ currentCountry.toUpperCase() } </span>HQ&#39;s Highest Payouts
					</h1>
					<RoundList
						hasPagination={ true }
						component={ HighestPayoutListRound }
						rounds={ rounds }
						{ ...props }
					/>
				</main>

				<Secondary { ...props } />

			</div>
		</div>
	);
};

export default withLayout( TagArchiveList );
