/**
 * Internal dependencies.
 */
import { countryList } from './l10n.js';

/**
 * Determine if the current page is the homepage.
 *
 * @return bool
 */
export const isHomepage = function() {
	const path = window.location.pathname;
	let isHome = false;

	if ( '/' === path ) {
		isHome = true;
	}

	countryList.map( ( country ) => {
		if ( `/${ country.short_name }` === path ) {
			isHome = true;
		}
	} );

	return isHome;
};
