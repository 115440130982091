/**
 * External dependencies.
 */
import moment from 'moment';
/* eslint-disable no-unused-vars */
import { tz } from 'moment-timezone';
/* eslint-enable no-unused-vars */

// List of available countries.
// @todo maybe hydrate from page?
export const countryList = [
	{
		short_name: 'us',
		full_name: 'United States',
	},
	{
		short_name: 'uk',
		full_name: 'United Kingdom',
	},
	{
		short_name: 'de',
		full_name: 'Germany',
	},
	{
		short_name: 'au',
		full_name: 'Australia',
	},
];

/**
 * Create a URL for a new country.
 *
 * @param {string} currentCountry Current country.
 * @param {string} nextCountry Next country.
 * @return {string} New path.
 */
export const smartContextUrl = ( currentCountry, nextCountry ) => {
	return `/${ nextCountry }`;
};

/**
 * Return a singular or plural noun based on a count.
 *
 * @param {string} singular Singular to return.
 * @param {string} plural Plural to return.
 * @param {string|number} count Count to compre.
 * @return {string} Correct form based on count.
 */
export const _n = ( singular, plural, count ) => {
	return 1 === parseInt( count ) ? singular : plural;
};

/**
 * Turn a Date object human readable.
 *
 * @param {Date} date Date object.
 * @return {string} Human readable date.
 */
export const humanDate = ( date ) => {
	const { currentTimezone } = hqbuff;

	const newDate = moment( date );

	newDate.tz( currentTimezone );

	return newDate.format( 'MMMM Do, YYYY h:mm A' );
};

/**
 * Return the currency symbol based on a passed code.
 *
 * @param {string} currency Currency abbreviation (USD).
 * @return {string} Currency symbol.
 */
export const getSymbolFromCurrency = ( currency ) => {
	switch ( currency ) {
		case '€EUR':
			return '€';
		case 'USD':
			return '$';
		case 'GBP':
			return '£';
	}
};

/**
 * Return the currency name based on a passed symbol.
 *
 * @param {string} symbol Currency symbol.
 * @return {string} Currency abbreviation (USD).
 */
export const getCurrencyFromSymbol = ( symbol ) => {
	switch ( symbol ) {
		case '€':
			return 'EUR';
		case '$':
			return 'USD';
		case '£':
			return 'GBP';
	}
};

/**
 * Create a formatted currency.
 *
 * Currently it just appends the symbol before the amount.
 *
 * @param {string} symbol Currency symbol.
 * @param {string} amount Amount.
 * @return {string} Formatted currency string.
 */
export const getCurrencyFormat = ( symbol, amount ) => {
	// Assume API returned a semi-formatted amount.
	if ( ! Number.isInteger( amount ) ) {
		return symbol + amount;
	}

	// Attempt to format a true int.
	const { currentCountry } = hqbuff;

	const numberFormat = new Intl.NumberFormat( currentCountry, {
		style: 'currency',
		currency: getCurrencyFromSymbol( symbol ),
	} );

	return numberFormat.format( amount );
};

/**
 * Add (Page 2) if on a non-first page.
 *
 * @param {number} currentPage Current page number.
 * @return {mixed} Nothing or string of (Page 2).
 */
export const getPageNumberAppender = ( currentPage ) => {
	const page = parseInt( currentPage );

	if ( ! page ) {
		return null;
	}

	if ( page === 1 ) {
		return null;
	}

	return ` (Page ${ page })`;
};

export const capitalizeWord = ( word ) => {
	return word.replace( /^\w/, function( chr ) {
		return chr.toUpperCase();
	} );
};
