/**
 * External dependencies.
 */
import React from 'react';

const SingleRound = () => (
	<div className="round ph-item" style={ { padding: '30px 0' } }>
		<div className="ph-col-12" style={ { margin: '0' } }>
			<div className="ph-row" >
				<div className="ph-col-2" style={ { margin: '-10px 0 15px' } }></div>
				<div className="ph-col-10 empty"></div>
				<div className="ph-col-10 big"></div>
				<div className="ph-col-6"></div>
				<div className="ph-col-6 empty"></div>
				<div className="ph-col-6"></div>
				<div className="ph-col-6 empty"></div>
				<div className="ph-col-6"></div>
				<div className="ph-col-6 empty"></div>
				<div className="ph-col-12 big" style={ { margin: '20px 0 -15px' } }></div>
			</div>
		</div>
	</div>
);

export default SingleRound;
