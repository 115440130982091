/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Title from './title.js';
import Meta from './meta.js';

import Answers from 'app/components/game-types/trivia/round/answers';

const CategoryRound = ( props ) => {
	const { text: hint } = props;

	return (
		<div className="round round--trivia">
			<Title { ...props } />
			<h3 className="round-hint">{ hint }</h3>
			<Answers { ...props } />
			<Meta { ...props } />
		</div>
	);
};

export default CategoryRound;
