/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';

import Secondary from 'app/components/layout/secondary';

import TabList from 'app/components/game-tab-list';
import GamePaginationBar from 'app/components/game-pagination-bar';
import GamePagination from 'app/components/game-pagination';

import HQTrivia from 'app/components/game-types/trivia';
import HQWords from 'app/components/game-types/words';

import { isHomepage, humanDate } from 'utils';

const Game = ( props ) => {
	const { currentGame: { start_time: startTime, game_type: gameType }, currentGame, isAdmin } = props;
	const TitleTag = isHomepage() ? 'p' : 'h1';

	const GameComponent = {
		trivia: HQTrivia,
		words: HQWords,
	}[ gameType ];

	return (
		<div className="container site site--game">
			<div className="split primary-content">
				<main role="main" className="games split__1">
					<GamePaginationBar { ...props } />
					<TabList { ...props } />
					<TitleTag className="screen-reader-text">{ humanDate( startTime ) }</TitleTag>

					<GameComponent { ...props } />

					<div className="game-pagination">
						<GamePagination { ...props } nextText="Next Day &rarr;" prevText="&larr; Previous Day" />
					</div>
				</main>

				<Secondary { ...props } />
			</div>
		</div>
	);
};

export default withLayout( Game );
