/**
 * External dependencies.
 */

import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Answer from './answer.js';

class Answers extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			showingAnswer: false,
			results: props.results,
		};

		this.toggleAnswer = this.toggleAnswer.bind( this );
	}

	toggleAnswer() {
		this.setState( {
			showingAnswer: ! this.state.showingAnswer,
		} );
	}

	render() {
		const { showingAnswer, results } = this.state;

		return (
			<ul className="trivia-answer-list">
				{ results.map( ( answerData ) => (
					<Answer
						key={ answerData.id }
						showingAnswer={ showingAnswer }
						toggleFunc={ this.toggleAnswer }
						{ ...answerData }
					/>
				) ) }
			</ul>
		);
	}
}

export default Answers;
