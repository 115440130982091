/**
 * External dependencies.
 */
import React from 'react';

const ArchivePagination = ( { next, prev } ) => {
	if ( ! next && ! prev ) {
		return null;
	}

	return (
		<nav aria-label="Category archive pages">
			<ul className="pagination">
				{ prev && (
					<li className="page-item"><a href={ prev.replace( '/api', '' ) } className="page-link">Previous</a></li>
				) }

				{ next && (
					<li className="page-item"><a href={ next.replace( '/api', '' ) } className="page-link">Next</a></li>
				) }
			</ul>
		</nav>
	);
};

export default ArchivePagination;
