/**
 * External dependencies.
 */
import React from 'react';

const currentYear = new Date().getFullYear();
const copyDates = 2018 === currentYear ? currentYear : `2018–${ currentYear }`;

const Mastfoot = () => (
	<footer className="footer">
		<div className="container">
			<p>Copyright &copy; { copyDates }. For educational purposes only. HQ Buff is not endorsed by or affiliated with Intermedia Labs or the HQ Trivia application. HQ Trivia, HQ Words, and HQ Sports logos are property of their respective owners.</p>
			<p>
				<a href="mailto:hqbuff@gmail.com">Contact</a>
				<span className="text-muted">&mdash;</span>
				<a href="https://twitter.com/hqbuff">@hqbuff</a>
			</p>
		</div>
	</footer>
);

export default Mastfoot;
