/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Tile from './../tile.js';
import { getLettersFromWords } from 'app/components/game-types/words/utils';
import { inArray } from 'utils';

/**
 * Determine if the current letter is active (it was entered by the keyboard).
 *
 * @param {string} letter Letter to check.
 * @param {Object} props Component properties.
 * @return {boolean} Is active?
 */
const isActiveLetter = ( letter, props ) => {
	const { words, correctLetters, isOver } = props;
	const totalLetters = getLettersFromWords( words );

	// Letter just exists in correct letter list.
	if ( inArray( letter, correctLetters ) ) {
		return true;
	}

	// Game ended and total letters in the answer is equal to the number of correct letters.
	if ( isOver && totalLetters.length === correctLetters.length ) {
		return true;
	}
};

/**
 * Determine if the current letter is revealed automatically.
 *
 * @param {string} letter Letter to check.
 * @param {Object} props Component properties.
 * @return {boolean} Is revealed?
 */
const isRevealedLetter = ( letter, props ) => {
	const { revealedLetters } = props;

	// Exists in list of revealed letters.
	if ( inArray( letter, revealedLetters ) ) {
		return true;
	}
};

/**
 * Determine if the current letter is incorrect (game is over).
 *
 * @param {string} letter Letter to check.
 * @param {Object} props Component properties.
 * @return {boolean} Is incorrect?
 */
const isWrongLetter = ( letter, props ) => {
	const { isOver, correctLetters, words } = props;
	const totalLetters = getLettersFromWords( words );

	// Game is over and total letters in answer is not correct answer.
	if ( isOver && totalLetters.length !== correctLetters.length ) {
		return true;
	}
};

const AnswerLetter = ( props ) => {
	const { letter, isOver } = props;

	const className = classnames( {
		'is-revealed': isRevealedLetter( letter, props ),
		'is-active': isActiveLetter( letter, props ),
		'is-wrong': isWrongLetter( letter, props ),
		'is-over': isOver,
	} );

	return <Tile className={ className } letter={ letter } />;
};

export default AnswerLetter;
