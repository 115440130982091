/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

const Meta = ( { eliminated, advancing, direct_link: directLink } ) => (
	<p className="round-meta">
		<span>{ advancing } advancing</span>
		<span>{ eliminated } eliminated</span>
		<span className="round-meta__pull-right">
			<a href={ directLink }>View Full Game &rarr;</a>
		</span>
	</p>
);

export default Meta;
