/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Widget from 'app/components/widget';
import Badge from 'app/components/badge';
import { capitalizeWord } from 'utils';

class TagList extends Component {
	constructor() {
		super();

		this.state = {
			tags: null,
		};
	}

	componentDidMount() {
		const { currentCountry, currentGameType } = hqbuff;

		fetch( `/api/${ currentCountry }/tag/${ currentGameType }?limit=15`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				this.setState( {
					tags: data.items,
				} );
			} );
	}

	render() {
		const { currentCountry, currentGameType } = hqbuff;
		const { tags } = this.state;
		const title = `${ capitalizeWord( currentGameType ) } Tags`;

		if ( ! tags ) {
			return <Widget title={ title }>Loading...</Widget>;
		}

		return (
			<Widget title={ title } className="widget--cloud">
				{ tags.map( ( term ) => (
					<li key={ term.slug }>
						<Badge
							href={ `/${ currentCountry }/tag/${ hqbuff.currentGameType }/${ term.slug }` }
							level="secondary"
							meta={ term.count }
						>
							{ term.name }
						</Badge>
					</li>
				) ) }

				<a href={ `/${ currentCountry }/game/${ currentGameType }/tags` } className="widget__more"><strong>View More &rarr;</strong></a>
			</Widget>
		);
	}
}

export default TagList;
