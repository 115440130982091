import React from 'react';

import ReactPlayer from 'react-player';

const Media = ( { element } ) => {
	if ( ! element ) {
		return '';
	}

	return (
		<div className="player-wrapper">
			<ReactPlayer
				className="react-player"
				url={ element.presigned }
				width="100%"
				height="100%"
				controls
			/>
		</div>
	);
};

export default Media;
