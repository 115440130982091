/**
 * External dependencies.
 */
import React, { Fragment } from 'react';

/**
 * Internal dependencies.
 */
import { ROUND_DURATION } from 'app/components/game-types/words/utils';

import ShareButton from 'app/components/game-share-button';
import Countdown from 'app/components/circular-countdown';

const Title = ( { question_number: questionNumber, isPlaying, isOver, elapsedTime, startRound, showAnswer, direct_link: directLink } ) => (
	<div className="round-title">
		<a href={ directLink } className="round-title__number">{ questionNumber }</a>

		{ isOver && (
			<ShareButton url={ directLink } />
		) }

		{ ( ! isPlaying && ! isOver ) && (
			<Fragment>
				<button type="button" className="round-title__button" onClick={ showAnswer }>Show Answer</button>
				<button type="button" className="round-title__button" onClick={ startRound }>Play Round</button>
			</Fragment>
		) }

		{ isPlaying && (
			<Countdown elapsedTime={ elapsedTime } isOver={ isOver } duration={ ROUND_DURATION } />
		) }
	</div>
);

export default Title;
