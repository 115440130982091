/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Title from './title.js';
import Meta from './meta.js';

import Answers from 'app/components/game-types/trivia/round/answers';
import MissingRound from 'app/components/game-missing-round';
import Checkpoint from './checkpoint.js';
import Media from './media';

const DefaultRound = ( props ) => {
	const {
		question_number: questionNumber,
		text: hint,
		savage,
		checkpoint,
		media_element,
	} = props;

	// @todo make sure this is right.
	if ( props == null || '' === hint || undefined === hint ) {
		return <MissingRound questionNumber={ questionNumber } />;
	}

	return (
		<div className="round round--trivia" id={ questionNumber }>
			<Title { ...props } />
			<h3 className="round-hint">{ hint }</h3>
			<Media element={ media_element } />
			<Answers key={ questionNumber } { ...props } />
			<Meta { ...props } checkpoint={ checkpoint } />
		</div>
	);
};

export default DefaultRound;
