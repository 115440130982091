/**
 * External dependencies.
 */
import React from 'react';

const MissingRound = ( { questionNumber } ) => (
	<div className="round" id={ questionNumber }>
		<div className="round-title">
			<div className="round-title__number">{ questionNumber }</div>
		</div>
		<div className="round-hint">
      This question was missed due to a connection error.
		</div>
	</div>
);

export default MissingRound;
