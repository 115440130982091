const userFields = [
	'jwt',
	'admin',
];

/**
 * "Log in" a user with their JWT and user information.
 *
 * @param {Object} data User data.
 */
export const loginUser = ( data ) => {
	userFields.map( ( field ) => {
		if ( ! data[ field ] ) {
			return;
		}

		localStorage.setItem( field, data[ field ] );
	} );

	window.location.replace( '/' );
};

/**
 * Log out a user.
 *
 * @param {Object} data User data.
 */
export const logoutUser = () => {
	localStorage.clear();
	window.location.replace( '/' );
};

/**
 * Get user data.
 *
 * @return {Object|null}
 */
export const getUserData = () => {
	const data = {};

	userFields.map( ( field ) => {
		data[ field ] = localStorage.getItem( field );
	} );

	return data;
};

/**
 * Is a user logged in?
 *
 * @return {Object|null}
 */
export const isUserLoggedIn = () => {
	return !! getUserData().jwt;
};

/**
 * Is the logged in user an admin?
 *
 * @return {Object|null}
 */
export const isUserAdmin = () => {
	return !! ( getUserData().jwt && getUserData().admin );
};
