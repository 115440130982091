/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ParticipatingCountries from 'app/components/game-participating-countries';

import { _n } from 'utils';

const Summary = ( props ) => {
	const { summary: { winner_count: winnerCount, currency, average_prize: prize }, practice, countries, checkpoints,
		total_checkpoint_winners: totalCheckpointWinners, smallest_checkpoint_prize: smallestCheckpointPrize, largest_checkpoint_prize: largestCheckpointPrize, total_checkpoint_prize: totalCheckpointPrize } = props;
	const unformattedWinnerCount = parseFloat( winnerCount.replace( /,/g, '' ) );
	const unformattedTotalCheckpointWinners = totalCheckpointWinners ? parseFloat( totalCheckpointWinners.replace( /,/g, '' ) ) : 0;

	// Don't show a summary if game is practice,
	// or, we captured zero questions,
	// or, we are missing questions and winner count is zero, and checkpoint winner count is 0 (hack to assume we missed final packet)
	return props.practice || props.questions.length == 0 || ( props.questions.includes( null ) && parseInt( unformattedWinnerCount ) === 0 && totalCheckpointWinners === 0 ) ? '' : (
		<div id="game-summary" className="game-summary">
			{ parseInt( unformattedWinnerCount ) !== 0 && (
				<div className="trivia-winners">
					{ winnerCount } { _n( 'person', 'people', unformattedWinnerCount ) } won around <span className="prize">{ currency + prize }</span> from the jackpot
				</div>
			) }

			{ ( checkpoints && checkpoints.length !== 0 ) && (
				<div className="trivia-winners">
					{ totalCheckpointWinners } { _n( 'person', 'people', unformattedTotalCheckpointWinners ) } won <span className="prize">{ smallestCheckpointPrize }</span>-<span className="prize">{ largestCheckpointPrize }</span> from checkpoints totaling <span className="prize">{ currency }{ totalCheckpointPrize }</span>
				</div>
			) }

			<div className="game__participating-countries">
				<span className="text-muted">Participating countries:</span>
				<ParticipatingCountries countries={ countries } />
			</div>
		</div>
	);
};

export default Summary;
