/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { humanDate } from 'utils';

const Meta = ( { date, initial_count: initialCount, direct_link: directLink } ) => (
	<p className="round-meta">
		<span>{ humanDate( date ) }</span>
		<span>{ initialCount } Initial Players</span>

		<span className="round-meta__pull-right">
			<a href={ directLink }>View Full Game &rarr;</a>
		</span>
	</p>
);

export default Meta;
