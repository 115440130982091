/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Letter from './letter.js';

const AnswerWord = ( props ) => {
	const { word } = props;
	const letters = word.split( '' );

	return (
		<div className="words-answer__word">
			{ letters.map( ( letter, i ) => (
				<Letter
					key={ `${ letter }-${ i }` }
					letter={ letter }
					{ ...props }
				/>
			) ) }
		</div>
	);
};

export default AnswerWord;
