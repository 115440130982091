/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Title from './title.js';
import Answers from './answers.js';

const Survey = ( props ) => {
	const {
		question: question,
		surveyNumber,
		gameLink,
	} = props;

	return (
		<div className="round round--survey" id={ `survey-${ surveyNumber + 1 }` }>
			<Title { ...props } />
			<h3 className="round-hint">{ question }</h3>
			<Answers { ...props } />
		</div>
	);
};

export default Survey;
