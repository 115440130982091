/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ShareButton from 'app/components/game-share-button';

const ShowAnswerButton = ( props ) => {
	const { direct_link: directLink } = props;
	let { label } = props;

	if ( ! label ) {
		label = 'Show Answer';
	}

	return (
		<ShareButton url={ directLink } />
	);
};

export default ShowAnswerButton;
