/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component, Fragment } from 'react';

/**
 * Internal dependencies.
 */
import ManageTags from './tags-manage.js';

import Badge from 'app/components/badge';
import Popover from 'app/components/popover';

import { isUserAdmin, getUserData } from 'utils';

class Tags extends Component {
	constructor( props ) {
		super( props );

		const { currentGame: { tags: currentTags } } = this.props;

		this.state = {
			availableTags: [],
			currentTags,
			customTag: '',
		};

		this.addTag = this.addTag.bind( this );
		this.removeTag = this.removeTag.bind( this );

		this.addCustomTag = this.addCustomTag.bind( this );
		this.updateCustomTag = this.updateCustomTag.bind( this );
	}

	componentDidMount() {
		const { currentCountry, currentGameType } = hqbuff;

		fetch( `/api/${ currentCountry }/tag/${ currentGameType }?sort=true`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				this.setState( {
					availableTags: data,
				} );
			} );
	}

	updateCustomTag( e ) {
		this.setState( {
			customTag: e.target.value,
		} );
	}

	addCustomTag() {
		const { customTag } = this.state;

		this.addTag( {
			name: customTag,
		} );
	}

	addTag( tag ) {
		const { currentGame: { show_id } } = hqbuff;
		const { name } = tag;

		fetch( '/api/tag', {
			method: 'POST',
			body: JSON.stringify( {
				show_id,
				tag: name,
			} ),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${ getUserData().jwt }`,
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				this.setState( {
					currentTags: [
						...this.state.currentTags,
						tag,
					],
				} );
			} );
	}

	removeTag( tag ) {
		const { currentGame: { id } } = hqbuff;
		const { name } = tag;

		fetch( '/api/tag', {
			method: 'DELETE',
			body: JSON.stringify( {
				game_id: id,
				tag: name,
			} ),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${ getUserData().jwt }`,
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				this.setState( {
					currentTags: this.state.currentTags.filter( ( aTag ) => {
						return aTag.slug !== tag.slug;
					} ),
				} );
			} );
	}

	render() {
		const { currentCountry } = hqbuff;
		const { currentTags } = this.state;

		return (
			<Fragment>
				{ currentTags.map( ( tag ) => (
					<Badge
						key={ tag.slug }
						href={ `/${ currentCountry }/tag/${ hqbuff.currentGameType }/${ tag.slug }` }
						level="secondary"
					>
						{ tag.name }
					</Badge>
				) ) }

				{ isUserAdmin() && (
					<Popover content={ (
						<ManageTags
							addTag={ this.addTag }
							removeTag={ this.removeTag }
							updateCustomTag={ this.updateCustomTag }
							addCustomTag={ this.addCustomTag }
							{ ...this.state }
						/>
					) }>
						<span className="text-small">Manage Tags</span>
					</Popover>
				) }
			</Fragment>
		);
	}
}

export default Tags;
