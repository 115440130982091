/**
 * External dependencies.
 */
import React from 'react';

const Hint = ( props ) => {
	const {
		elapsedTime,
		hasUsedKeyboard,
	} = props;

	if ( elapsedTime <= 14 ) {
		return null;
	}

	if ( hasUsedKeyboard ) {
		return null;
	}

	return <p className="words-typing-hint">Use your keyboard to guess letters to complete the puzzle.</p>;
};

export default Hint;
