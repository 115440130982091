/**
 * External dependencies.
 */
import React, { Fragment } from 'react';

/**
 * Internal dependencies.
 */
import { DefaultRound as TriviaRound } from './round-output-variations';
import Summary from './summary';
import Survey from './round-output-variations/survey';

import GameTitle from 'app/components/game-title';
import { SingleRoundPlaceholder } from 'app/components/placeholders';

const HQTrivia = ( props ) => {
	const { currentGame, currentGameIsActive } = props;

	return (
		<Fragment>
			<GameTitle { ...props } />

			{ currentGame.surveys.length > 0 ? (
				<Fragment>
					<strong className="round-list-title">Surveys:</strong>
					<div className="round-list" style={ { marginBottom: '15px' } }>
						{ currentGame.surveys.map( ( survey, i ) => (
							<Survey gameLink={ currentGame.direct_link } surveyNumber={ i } key={ i } { ...survey } />
						) ) }
					</div>

				</Fragment>
			) : null }

			<strong className="round-list-title">Trivia:</strong>
			<div className="round-list">
				{ currentGame.questions.map( ( questionData, i ) => (
					<TriviaRound key={ questionData !== null ? questionData.id : ( `missing-question-${ currentGame.id }-${ i + 1 }` ) } { ...questionData } />
				) ) }

				{ ! currentGameIsActive && currentGame.questions.length == 0 ? (

					<div className="round round--trivia">
              No Data
					</div>
				) : ''
				}

				{ currentGameIsActive ? (
					<SingleRoundPlaceholder />
				) : (
					<Summary { ...currentGame } />
				) }
			</div>
		</Fragment>
	);
};

export default HQTrivia;
