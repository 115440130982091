/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';
import { humanDate } from 'utils';

import Secondary from 'app/components/layout/secondary';
import RoundList from 'app/components/round-list';

const Game = ( props ) => {
	const { direct_link: directLink, start_time: startTime } = props;

	return (
		<div className="round">
			<a href={ directLink }>{ humanDate( startTime ) }</a>
		</div>
	);
};

const TagArchiveList = ( props ) => {
	const { items: tags } = props;
	const { tagName, country: { shortName: currentCountry } } = hqbuff;

	return (
		<div className="container site">
			<div className="split primary-content">

				<main role="main" className="games split__1">
					<h1 className="page-title">
						<span className="screen-reader-text">{ currentCountry.toUpperCase() } </span>&quot;{ tagName }&quot; Games
					</h1>
					<RoundList
						rounds={ tags }
						hasPagination={ true }
						component={ Game }
						{ ...props }
					/>
				</main>

				<Secondary { ...props } />

			</div>
		</div>
	);
};

export default withLayout( TagArchiveList );
