/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Tile from './../tile.js';

const Lives = ( { incorrectLetters } ) => {
	const letters = [];

	for ( let i = 0; i <= 2; i++ ) {
		letters.push(
			<Tile
				className={ incorrectLetters[ i ] ? 'is-wrong' : 'is-life' }
				key={ i }
				letter={ incorrectLetters[ i ] || '×' }
			/>
		);
	}

	return letters;
};

export default Lives;
