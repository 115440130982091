/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import Game from 'app/layouts/game';
import NotFound from 'app/layouts/not-found';
import { isUserLoggedIn, isUserAdmin } from 'utils';

// Collection of games in a day
class GameContainer extends Component {
	constructor( props ) {
		super( props );

		const { currentGames, currentGameNumber } = hqbuff;

		this.state = {
			currentGameIsActive: false,
			currentGame: currentGames ? currentGames[ parseInt( currentGameNumber ) - 1 ] : false,
			currentGames,
			isAdmin: isUserLoggedIn() && isUserAdmin(),
		};

		this.tick = this.tick.bind( this );
	}

	/**
   * - Find the current game based on bootstrapped data.
   * - If the current game is active, start a live refresh.
   */
	componentDidMount() {
		const { currentGame } = this.state;

		if ( ! currentGame ) {
			return;
		}

		// Set as active and start refresh.
		if ( currentGame.active ) {
			this.setState( {
				currentGameIsActive: currentGame.active,
			} );

			// Start the timer.
			this.interval = setInterval( this.tick, 3000 );
		}
	}

	/**
   * After updating the state, destroy the tick if the game is not active anymore.
   */
	componentDidUpdate() {
		const { currentGameIsActive } = this.state;

		if ( ! currentGameIsActive ) {
			clearInterval( this.tick );
		}
	}

	/**
   * Check the API for new game data.
   */
	tick() {
		const { currentCountry, currentDate, currentGameNumber } = hqbuff;

		fetch( `/api/${ currentCountry }/${ currentDate }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		} )
			.then( ( response ) => response.json() )
			.then( ( games ) => {
				const currentGame = games[ currentGameNumber - 1 ];

				return this.setState( {
					currentGame,
					currentGameIsActive: currentGame.active,
				} );
			} );
	}

	render() {
		return this.state.currentGame ? <Game { ...this.state } /> : <NotFound />;
	}
}

export default GameContainer;
