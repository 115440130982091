/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';

import Secondary from 'app/components/layout/secondary';

import HQTrivia from 'app/components/game-types/trivia';
import HQWords from 'app/components/game-types/words';

const Practice = ( props ) => (
	<div className="container site site--game">
		<div className="split primary-content">
			<main role="main" className="games split__1">
				{ props.game_type === 'trivia' || (props.show && props.show.game_type == "tunes") ? <HQTrivia currentGame={ props } /> : <HQWords currentGame={ props } /> }
			</main>

			<Secondary { ...props } />
		</div>
	</div>
);

export default withLayout( Practice );
