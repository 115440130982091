/**
 * External dependencies.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import { default as routes } from './routes';

import NotFound from 'app/layouts/not-found.js';

const App = () => (
	<Router>
		<Switch>
			{ routes.map( ( route ) => (
				<Route key={ route.path } exact path={ route.path } render={ () => <route.component /> } />
			) ) }
			<Route component={ NotFound } />
		</Switch>
	</Router>
);

const stub = document.getElementById( 'app' );

if ( stub ) {
	ReactDOM.render( <App />, stub );
}
