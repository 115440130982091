/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ShowAnswerButton from 'app/components/game-types/trivia/show-answer-button';

const Title = ( props ) => {
	const { surveyNumber, __unstableHideQuestionNumber, gameLink } = props;

	return (
		<div className="round-title">
			{ ! __unstableHideQuestionNumber && (
				<span className="round-title__number">{ surveyNumber + 1 }</span>
			) }
			<ShowAnswerButton { ...props } label="Show Results" direct_link={ `${ gameLink }#survey-${ surveyNumber + 1 }` } />
		</div>
	);
};

export default Title;
