/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component, Fragment } from 'react';

/**
 * Internal dependencies.
 */
import Stats from 'app/layouts/stats';
import Highcharts from 'highcharts';
import * as hcnd from 'highcharts/modules/no-data-to-display';
hcnd( Highcharts );

class StatsContainer extends Component {
	constructor() {
		super();
	}

	componentDidMount() {
		Highcharts.setOptions( { lang: { noData: 'Your custom message' } } );
		this.totalDailyPlayers = Highcharts.chart( 'total-daily-players-timeseries', {
			chart: {
				zoomType: 'x',
			},
			title: {
				text: 'Total Daily Player Counts',
			},
			navigator: {
				enabled: true,
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
			},
			xAxis: {
				type: 'datetime',
			},
			yAxis: {
				title: {
					text: 'Daily Number of Players',
				},
			},

			time: {
				timezone: 'America/New_York',
			},
			legend: {
				enabled: true,
			},
			lang: {
				noData: 'No data to display.',
			},
			plotOptions: {
				area: {
					fillColor: {
						linearGradient: {
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1,
						},
						stops: [
							[ 0, Highcharts.getOptions().colors[ 0 ] ],
							[ 1, Highcharts.Color( Highcharts.getOptions().colors[ 0 ] ).setOpacity( 0 ).get( 'rgba' ) ],
						],
					},
					marker: {
						radius: 2,
					},
					lineWidth: 1,
					states: {
						hover: {
							lineWidth: 1,
						},
					},
					threshold: null,
				},
			},
		} );

		this.totalDailyPrize = Highcharts.chart( 'total-daily-prize-timeseries', {
			chart: {
				zoomType: 'x',
			},
			title: {
				text: 'Total Daily Prize (Trivia)',
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
			},
			xAxis: {
				type: 'datetime',
			},
			yAxis: {
				title: {
					text: 'Total Daily Prize Pool (Trivia)',
				},
			},

			time: {
				timezone: 'America/New_York',
			},
			legend: {
				enabled: true,
			},
			lang: {
				noData: 'No data to display.',
			},
			plotOptions: {
				area: {
					fillColor: {
						linearGradient: {
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1,
						},
						stops: [
							[ 0, Highcharts.getOptions().colors[ 0 ] ],
							[ 1, Highcharts.Color( Highcharts.getOptions().colors[ 0 ] ).setOpacity( 0 ).get( 'rgba' ) ],
						],
					},
					marker: {
						radius: 2,
					},
					lineWidth: 1,
					states: {
						hover: {
							lineWidth: 1,
						},
					},
					threshold: null,
				},
			},
		} );

		this.totalDailyPrizeAwarded = Highcharts.chart( 'total-daily-prize-awarded-timeseries', {
			chart: {
				zoomType: 'x',
			},
			title: {
				text: 'Total Daily Prize Awarded (Trivia)',
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
			},
			xAxis: {
				type: 'datetime',
			},
			yAxis: {
				title: {
					text: 'Total Daily Prize Awarded (Trivia)',
				},
			},
			legend: {
				enabled: true,
			},
			lang: {
				noData: 'No data to display.',
			},
			plotOptions: {
				area: {
					fillColor: {
						linearGradient: {
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1,
						},
						stops: [
							[ 0, Highcharts.getOptions().colors[ 0 ] ],
							[ 1, Highcharts.Color( Highcharts.getOptions().colors[ 0 ] ).setOpacity( 0 ).get( 'rgba' ) ],
						],
					},
					marker: {
						radius: 2,
					},
					lineWidth: 1,
					states: {
						hover: {
							lineWidth: 1,
						},
					},
					threshold: null,
				},
			},
		} );

		this.totalShowsByCountry = Highcharts.chart( 'total-shows-by-country', {
			chart: {
				type: 'bar',
			},
			title: {
				text: 'Total Shows By Type (US)',
			},
			xAxis: {
				categories: [ 'Trivia', 'Sports', 'Words', 'Tunes' ],
				title: {
					text: null,
				},
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Show Count',
					align: 'high',
				},
				labels: {
					overflow: 'justify',
				},
			},
			plotOptions: {
				bar: {
					dataLabels: {
						enabled: true,
					},
				},
			},
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'top',
				x: -40,
				y: 80,
				floating: true,
				borderWidth: 1,
				backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
				shadow: true,
			},
			credits: {
				enabled: false,
			},
		} );

		this.totalShowsByCountry.showLoading();
		this.totalDailyPlayers.showLoading();
		this.totalDailyPrize.showLoading();
		this.totalDailyPrizeAwarded.showLoading();

		fetch(
			'/api/stats/num_players_ts',
			{
				method: 'GET',
			}
		)
			.then( ( r ) => {
				return r.json();
			} )
			.then( ( data ) => {
				this.totalDailyPlayers.hideLoading();

				this.totalDailyPlayers.addSeries( {
					data: data.trivia,
					name: 'Total Daily Trivia Players',
				} );

				this.totalDailyPlayers.addSeries( {
					data: data.words,
					name: 'Total Daily Words Players',
				} );
			} );

		fetch(
			'/api/stats/total_prize_ts',
			{
				method: 'GET',
			}
		)
			.then( ( r ) => {
				return r.json();
			} )
			.then( ( data ) => {
				this.totalDailyPrize.hideLoading();

				this.totalDailyPrize.addSeries( {
					data: data,
					name: 'Total Daily Prize Pool',
				} );
			} );

		fetch(
			'/api/stats/total_prize_awarded_ts',
			{
				method: 'GET',
			}
		)
			.then( ( r ) => {
				return r.json();
			} )
			.then( ( data ) => {
				this.totalDailyPrizeAwarded.hideLoading();

				this.totalDailyPrizeAwarded.addSeries( {
					data: data.checkpoint_total,
					name: 'Total Daily Prize Awarded (Checkpoints)',
				} );

				this.totalDailyPrizeAwarded.addSeries( {
					data: data.jackpot_total,
					name: 'Total Daily Prize Awarded (Jackpot)',
				} );

				this.totalDailyPrizeAwarded.addSeries( {
					data: data.total,
					name: 'Total Daily Prize Awarded (Jackpot + Checkpoints)',
				} );
			} );

		fetch(
			'/api/stats/total_shows',
			{
				method: 'GET',
			}
		)
			.then( ( r ) => {
				return r.json();
			} )
			.then( ( data ) => {
				this.totalShowsByCountry.hideLoading();

				data.forEach( ( d ) => {
					this.totalShowsByCountry.addSeries( d );
				} );
			} );
	}

	render() {
		return (
			<Stats />
		);
	}
}

export default StatsContainer;
