/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { getPercentEliminated } from 'app/components/game-types/trivia/utils.js';
import ShowAnswerButton from 'app/components/game-types/trivia/show-answer-button';

const Title = ( props ) => {
	const { question_number: questionNumber, advancing, eliminated, direct_link: directLink, correct_answers: correctAnswers, incorrect_answers: incorrectAnswers } = props;

	return (
		<div className="round-title">
			<a href={ directLink } className="round-title__number">{ questionNumber }</a>
			<span className="round-title__eliminated">{ getPercentEliminated( incorrectAnswers, correctAnswers ) } Eliminated</span>
			<ShowAnswerButton { ...props } />
		</div>
	);
};

export default Title;
