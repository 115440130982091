import { default as gameRoutes } from './games.js';
import { default as categoryRoutes } from './categories.js';
import { default as pageRoutes } from './pages.js';
import { default as tagRoutes } from './tags.js';
import { default as userRoutes } from './user.js';
import { default as statsRoutes } from './stats.js';

export default [
	...gameRoutes,
	...categoryRoutes,
	...tagRoutes,
	...pageRoutes,
	...userRoutes,
	...statsRoutes,
];
