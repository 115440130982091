/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { getCurrencyFormat } from 'utils';
import ShowAnswerButton from 'app/components/game-types/trivia/show-answer-button';

const Title = ( props ) => {
	const { winner_count: winnerCount, prize, currency } = props;
	const singular = parseInt( winnerCount ) === 1;

	const who = `${ winnerCount } ${ singular ? 'person' : 'people' }`;
	const amount = getCurrencyFormat( currency, prize );

	return (
		<div className="round-title">
			<h3 className="highest-payout__last-question">{ who } won around <span className="prize">{ amount }</span>!</h3>
			<ShowAnswerButton { ...props } />
		</div>
	);
};

export default Title;
