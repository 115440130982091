/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { isHomepage } from 'utils';

const Branding = () => {
	const { currentCountry, page: { description: pageDescription } } = hqbuff;
	const TagName = isHomepage() ? 'h1' : 'p';

	return (
		<div className="branding">
			<TagName className="site-title">
				<a href={ `/${ currentCountry }` }>
					<span className="screen-reader-text">HQ Buff</span>
					<img src="/images/logo.png" alt="HQ Buff" />
				</a>
			</TagName>
			<p className="site-description screen-reader-text">{ pageDescription }</p>
		</div>
	);
};

export default Branding;
