/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';

const Login = ( props ) => {
	const { onSubmit, onChange, username, password, errors } = props;

	return (
		<div className="container site">
			<div className="primary-content">
				<h1>Login</h1>

				{ errors.map( ( error ) => (
					<p className="alert" key={ error }>{ error }</p>
				) ) }

				<form onSubmit={ onSubmit }>
					<p>
						<label htmlFor="username">Username:</label>
						<input type="text" name="username" value={ username } onChange={ onChange } required />
					</p>

					<p>
						<label htmlFor="password">Password:</label>
						<input type="password" name="password" value={ password } onChange={ onChange } required />
					</p>

					<p>
						<input type="submit" value="Log in" />
					</p>
				</form>

			</div>
		</div>
	);
};

export default withLayout( Login );
