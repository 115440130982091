/**
 * External dependencies.
 */
import React, { Fragment } from 'react';

/**
 * Internal dependencies.
 */
import Round from './round';
import SummaryTop3 from './summary_top_3';
import Summary from './summary';

import GameTitle from 'app/components/game-title';
import { SingleRoundPlaceholder } from 'app/components/placeholders';
import MissingRound from 'app/components/game-missing-round';

const HQWords = ( props ) => {
	const { currentGame, currentGameIsActive } = props;

	return (
		<Fragment>
			<GameTitle { ...props } />

			<div className="round-list">
				{ currentGame.rounds.map( ( roundData, i ) => ( roundData ? ( <Round key={ i } { ...roundData } /> ) : ( <MissingRound /> ) ) ) }

				{ currentGameIsActive ? (
					<SingleRoundPlaceholder />
				) : (
					currentGame.has_top_3 === true ?
					  ( <SummaryTop3 { ...currentGame } /> ) :
					  ( <Summary { ...currentGame } /> )

				) }
			</div>
		</Fragment>
	);
};

export default HQWords;
