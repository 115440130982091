/**
 * External dependencies.
 */
import React from 'react';

const Logo = ( { url, accentColor } ) => (
	<div className="upcoming-game__logo" data-accent={ accentColor }>
		<img src={ url } alt="" />
	</div>
);

export default Logo;
