/**
 * External dependencies.
 */
import domReady from '@wordpress/dom-ready';

/**
 * Internal dependencies.
 */
import { toggle as toggleEl, isMobile } from 'utils';

/**
 * Manage dropdowns.
 */
domReady( () => {
	const overlay = document.querySelector( '.dropdown-overlay' );

	if ( ! overlay ) {
		return;
	}

	// Find all toggles.
	const toggles = document.querySelectorAll( '.dropdown-toggle' );
	const toggleActiveClass = 'dropdown-toggle--active';
	const overlayActiveClass = 'dropdown-overlay--active';

	const hideAllDropdowns = () => {
		toggles.forEach( ( toggleNode ) => {
			// Hide dropdown.
			document.getElementById( toggleNode.dataset.nav ).style.display = 'none';

			// Remove active class.
			toggleNode.classList.remove( toggleActiveClass );

			// Remove overlay.
			overlay.classList.remove( overlayActiveClass );
			overlay.style.display = 'none';
		} );
	};

	toggles.forEach( ( toggleNode ) => {
		toggleNode.addEventListener( 'click', ( e ) => {
			e.preventDefault();

			const dropdown = document.getElementById( e.currentTarget.dataset.nav );
			const dropdownIsVisible = 'block' === dropdown.style.display;

			// Hide everything.
			hideAllDropdowns();

			// Show overlay.
			overlay.classList.toggle( overlayActiveClass );

			// Toggle active class on toggle.
			e.target.classList.toggle( toggleActiveClass );

			// Properly offset dropdown.
			// Currently only works with right aligned dropdowns.
			if ( isMobile() ) {
				const dims = e.currentTarget.getBoundingClientRect();
				const remainingOffset = window.innerWidth - ( dims.left + dims.width );

				dropdown.style.width = `${ window.innerWidth }px`;
				dropdown.style.right = `-${ remainingOffset }px`;
			} else {
				dropdown.style.width = '300px';
				dropdown.style.right = '-15px;';
			}

			// Toggle.
			toggleEl( dropdown, dropdownIsVisible ? 'none' : 'block' );
			toggleEl( overlay, dropdownIsVisible ? 'none' : 'block' );
		} );
	} );

	// Hide when things happen.
	overlay.addEventListener( 'click', hideAllDropdowns );
	window.addEventListener( 'resize', hideAllDropdowns );
	window.addEventListener( 'scroll', hideAllDropdowns );
} );
