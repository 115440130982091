/**
 * Internal dependencies.
 */
import SavageQuestionListContainer from 'app/containers/savage-question-list.js';
import HighestPayoutsListContainer from 'app/containers/highest-payouts-list.js';
import PracticeContainer from 'app/containers/practice.js';
import { countryList } from 'utils';

const routes = [];

countryList.map( ( country ) => {
	routes.push( {
		path: `/${ country.short_name }/savage-questions`,
		component: SavageQuestionListContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/highest-payouts`,
		component: HighestPayoutsListContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/practice/:gameType`,
		component: PracticeContainer,
	} );
} );

export default routes;
