
/* global hqbuff */

/**
 * Internal dependencies.
 */
import AllTagsArchiveList from 'app/layouts/all-tags-archive-list';
import { withPagination } from 'app/hoc';
import NotFound from 'app/layouts/not-found.js';

let Page;

Page = withPagination(
	AllTagsArchiveList,
	`tag/${ hqbuff.currentGameType }`
);

export default Page;
