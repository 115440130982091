/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Tab from './tab.js';

const TabList = ( props ) => {
	const { currentGames, currentGame } = props;

	return (
		<ul className="tabs" role="tablist">
			{ currentGames.map( ( game ) => (
				<Tab
					key={ game.game_number }
					isSelected={ currentGame.game_number === game.game_number }
					gameData={ game }
				/>
			) ) }
		</ul>
	);
};

export default TabList;

