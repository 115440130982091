/* global hqbuff */

/**
 * Internal dependencies.
 */
import TagArchiveList from 'app/layouts/tag-archive-list';
import { withPagination } from 'app/hoc';
import NotFound from 'app/layouts/not-found.js';

let Page;

if ( hqbuff.tagSlug ) {
	Page = withPagination(
		TagArchiveList,
		`tag/${ hqbuff.currentGameType }/${ hqbuff.tagSlug }`
	);
} else {
	Page = NotFound;
}

export default Page;
