/**
 * External dependencies.
 */
import { memoize } from 'lodash';

/**
 * Internal dependencies.
 */
import { letters, numbers, specialCharacters } from 'utils/keys.js';

export const validCharacters = [
	...letters,
	...numbers,
	...specialCharacters,
];

export const ROUND_DURATION = 20;
export const LETTER_REVEAL = 7;

/**
 * Return a list of letters based on a string of words.
 *
 * @param {mixed} words Words to get letters from. String or split by space.
 * @return {Array} Array of letters.
 */
export const getLettersFromWords = memoize( ( words ) => {
	const lettersMap = [];

	// Split in to an array if a string is used.
	const splitWords = Array.isArray( words ) ? words : words.split( ' ' );

	splitWords.map( ( word ) => word.split( '' ).map( ( letter ) => lettersMap.push( letter ) ) );

	// Everything should be caps anyway, but just in case. Heh. Case.
	return lettersMap.map( ( letter ) => letter.toUpperCase() );
} );
