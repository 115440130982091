/* global hqbuff */

/**
 * Internal dependencies.
 */
import GameArchiveList from 'app/layouts/game-archive-list';
import { withPagination } from 'app/hoc';
import NotFound from 'app/layouts/not-found.js';

let Page;

Page = withPagination(
	GameArchiveList,
	`game/${ hqbuff.currentGameType }`
);

export default Page;
