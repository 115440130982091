/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';

const Tile = ( props ) => {
	const { letter, className } = props;

	const mergedClassName = classnames(
		className,
		'words-tile',
	);

	return <span className={ mergedClassName }>{ letter }</span>;
};

export default Tile;
