/**
 * External dependencies.
 */
import React from 'react';
import { find } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Word from './word.js';

import { isMobile } from 'utils';

const Answer = ( props ) => {
	const { words } = props;

	const className = classnames(
		'words-answer',
		{
			'words-answer--has-long-word': isMobile() && find( words, ( word ) => word.length > 8 ),
		}
	);

	return (
		<div className={ className }>
			{ words.map( ( word, i ) => (
				<Word
					key={ word + i }
					word={ word }
					{ ...props }
				/>
			) ) }
		</div>
	);
};

export default Answer;
