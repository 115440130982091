/**
 * External dependencies.
 */
import React from 'react';

const Content = ( props ) => {
	const { content } = props;
	const contentSize = Math.min( window.innerWidth - 20, window.innerHeight - 20 );

	return (
		<div
			className="popover"
			style={ {
				maxHeight: contentSize,
				maxWidth: contentSize,
			} }
		>
			<div className="popover__content">
				{ content }
			</div>
		</div>
	);
};

export default Content;
