/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';

/**
 * Internal dependencies.
 */
import CountryList from 'app/components/country-list';

class CountryDropdown extends Component {
	constructor() {
		super();

		this.ref = React.createRef();
		this.maybeShow = this.maybeShow.bind( this );
	}

	componentDidMount() {
		window.addEventListener( 'scroll', this.maybeShow );
	}

	maybeShow() {
		const { pageYOffset } = window;

		this.ref.current.classList.toggle( 'game-pagination-bar__country-flag--active', 63 < pageYOffset );
	}

	render() {
		const { currentCountry, currentCountryName } = hqbuff;

		return (
			<div
				ref={ this.ref }
				id="game-pagination-flag"
				className="game-pagination-bar__country-flag dropdown-container"
			>
				<button id="country-toggle" className="country-toggle dropdown-toggle" data-nav="game-pagination-bar-country-list">
					<img className="flag" src={ `/images/flag-${ currentCountry }.svg` } />
					<span className="country-toggle__country-fullname">{ currentCountryName }</span>
					<span className="country-toggle__country-shortname" aria-hidden="true">{ currentCountry.toUpperCase() }</span>
				</button>

				<nav id="game-pagination-bar-country-list" className="game-pagination-bar__dropdown dropdown dropdown--right country-list">
					<CountryList />
				</nav>
			</div>
		);
	}
}

export default CountryDropdown;
