/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';
import moment from 'moment';

const Day = ( props ) => {
	const { currentCountry } = hqbuff;
	const { day } = props;

	return (
		<li>
			<a href={ `/${ currentCountry }/game/${ moment( day ).format( 'YYYY-MM-DD' ) }/1` }>
				<span className="screen-reader-text">HQ Trivia Quiz App Questions and Answers for { moment( day ).format( 'MMMM Do, YYYY' ) }</span>
				<span aria-hidden="true">{ moment( day ).format( 'Do' ) }</span>
			</a>
		</li>
	);
};

export default Day;
