/**
 * Internal dependencies.
 */
import HighestPayoutsList from 'app/layouts/highest-payouts-list';
import { withPagination } from 'app/hoc';

export default withPagination(
	HighestPayoutsList,
	'highest-payouts'
);
