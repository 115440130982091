/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';
import { getPageNumberAppender } from 'utils';

import Secondary from 'app/components/layout/secondary';

import RoundList from 'app/components/round-list';
import { SavageQuestionRound } from 'app/components/game-types/trivia/round-output-variations';

const CategoryArchiveList = ( props ) => {
	const { pagination: { current: currentPage }, items: rounds } = props;
	const { currentCountry } = hqbuff;

	return (
		<div className="container site">
			<div className="split primary-content">

				<main role="main" className="games split__1">
					<h1 className="page-title">
						<span className="screen-reader-text">{ currentCountry.toUpperCase() } </span> HQ&apos;s Most Savage Questions (10,000+ players)
						{ getPageNumberAppender( currentPage ) }
					</h1>

					<RoundList
						hasPagination={ true }
						component={ SavageQuestionRound }
						rounds={ rounds }
						{ ...props }
					/>
				</main>

				<Secondary { ...props } />

			</div>
		</div>
	);
};

export default withLayout( CategoryArchiveList );
