/**
 * Internal dependencies.
 */
import GameContainer from 'app/containers/game.js';
import SingleRoundContainer from 'app/containers/single-round.js';
import { countryList } from 'utils';
import GameArchiveListContainer from 'app/containers/game-archive-list.js';
import AllTagsArchiveListContainer from 'app/containers/all-tags-archive-list.js';
import AllCategoriesArchiveListContainer from 'app/containers/all-categories-archive-list.js';

const routes = [
	{
		path: '/',
		component: GameContainer,
	},
];

countryList.map( ( country ) => {
	routes.push( {
		path: `/${ country.short_name }`,
		component: GameContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/trivia`,
		component: GameArchiveListContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/words`,
		component: GameArchiveListContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/words/tags`,
		component: AllTagsArchiveListContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/trivia/tags`,
		component: AllTagsArchiveListContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/trivia/categories`,
		component: AllCategoriesArchiveListContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/:date`,
		component: GameContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/:date/:game`,
		component: GameContainer,
	} );

	routes.push( {
		path: `/${ country.short_name }/game/:date/:game/:round`,
		component: SingleRoundContainer,
	} );
} );

export default routes;
