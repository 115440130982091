
/* global hqbuff */

/**
 * Internal dependencies.
 */
import AllCategoriesArchiveList from 'app/layouts/all-categories-archive-list';
import { withPagination } from 'app/hoc';
import NotFound from 'app/layouts/not-found.js';

let Page;

Page = withPagination(
	AllCategoriesArchiveList,
	`category/${ hqbuff.currentGameType }`
);

export default Page;
