/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';
import { humanDate, capitalizeWord } from 'utils';

import Secondary from 'app/components/layout/secondary';
import RoundList from 'app/components/round-list';

const SingleTag = ( props ) => {
	const { name, slug, count } = props;

	return (
		<div className="round">
			<a href={ `/${ hqbuff.currentCountry }/tag/${ hqbuff.currentGameType }/${ slug }` } >{ name }</a>
		</div>
	);
};

const AllTagsArchiveList = ( props ) => {
	const { items: tags } = props;
	const { tagName, currentGameType, country: { shortName: currentCountry } } = hqbuff;

	return (
		<div className="container site">
			<div className="split primary-content">

				<main role="main" className="games split__1">
					<h1 className="page-title">
						<span className="screen-reader-text">{ currentCountry.toUpperCase() } </span>HQ { capitalizeWord( currentGameType ) } Tags
					</h1>
					<RoundList
						rounds={ tags }
						hasPagination
						component={ SingleTag }
					/>
				</main>

				<Secondary { ...props } />

			</div>
		</div>
	);
};

export default withLayout( AllTagsArchiveList );
