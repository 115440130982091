/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Badge from 'app/components/badge';

const Tab = ( props ) => {
	const { gameData, isSelected } = props;

	const className = classnames( {
		'tab-item': true,
		'tab-item--active': isSelected,
	} );

	return (
		<li className={ className }>
			<a href={ gameData.direct_link }>
				{ gameData.time }

				{ gameData.game_type === 'words' && (
					<Badge level="secondary">W</Badge>
				) }
			</a>
		</li>
	);
};

export default Tab;

