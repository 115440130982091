/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';

const NotFound = () => (
	<div className="container site">
		<div className="primary-content">
			<h1 className="alert" role="alert">Page Not Found. <a href="/">Go home &rarr;</a></h1>
		</div>
	</div>
);

export default withLayout( NotFound );
