/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Tags from './tags';

import { capitalizeWord } from 'utils';

const Title = ( props ) => {
	const { currentGame } = props;

	return (
		<div className="game-title">
			<h2 className="page-title game-title__prize">
				{ currentGame.practice ? ( `HQ ${ capitalizeWord( hqbuff.currentGameType ) } Practice` ) : ( <span className="prize">{ `${ currentGame.currency }${ currentGame.prize }` }</span> ) } { currentGame.practice ? `` : `Prize` }
			</h2>

			{ currentGame.practice ? ( <a
				className="paginatino-link"
				style={ { marginLeft: 'auto' } }
				href={ `/${ currentGame.country }/practice/${ currentGame.game_type }` }
			>
				<img src="/images/shuffle.svg" />
				{ currentGame.game_type == 'trivia' ? ( <span>New Questions</span> ) : <span>New Puzzles</span> }
			</a> ) : '' }

			<Tags { ...props } />
		</div>
	);
};

export default Title;

