/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { humanDate, getSymbolFromCurrency, getCurrencyFormat } from 'utils';
import Logo from './logo.js';

const Game = ( props ) => {
	const {
		startTime,
		prizeCents,
		currency,
		display: {
			title,
			logo,
			accentColor,
		},
	} = props;

	if ( ! startTime ) {
		return null;
	}

	const prize = getCurrencyFormat( getSymbolFromCurrency( currency ), prizeCents / 100 );

	return (
		<li className="upcoming-game">
			<Logo url={ logo } accentColor={ accentColor } />

			<div>
				{ title }: <strong className="prize">{ prize }</strong><br /><small>{ humanDate( startTime ) }</small>
			</div>
		</li>
	);
};

export default Game;
