/**
 * External dependencies.
 */
import React from 'react';
import { last } from 'lodash';

/**
 * Internal dependencies.
 */
import ParticipatingCountries from 'app/components/game-participating-countries';

import { _n } from 'utils';

const SummaryTop3 = ( props ) => {
	const { summary, countries } = props;
	const { winners, currency } = summary;

	// Reorder top 3 winners. 2rd, 1st, 3rd.
	const topWinners = [
		winners[ 1 ],
		winners[ 0 ],
		winners[ 2 ],
	];

	const loserWinners = last( winners );

	return (
		<div id="game-summary" className="game-summary">
			<div className="word-winners">
				{ topWinners.map( ( winner, i ) => {
					const { number, amount, time } = winner;

					return (
						<div className="word-winner" key={ number }>
							<div className={ `word-winner__trophy word-winner__trophy--${ number }` }>
								<span>{ number }</span>
								<img src="/images/trophy.svg" alt="Trophy" />
							</div>
							<div className="word-winner__amount prize">{ currency + amount }</div>
							{ time !== '0.00' && (
								<span className="word-winner__time">{ time }s</span>
							) }
						</div>
					);
				} ) }
			</div>

			<div className="word-winner-losers">
				and { loserWinners.number } { _n( 'person', 'other players', loserWinners.number ) } won around <span className="prize">{ currency + loserWinners.amount }</span>{ _n( '!', ' each!', loserWinners.number ) }
			</div>

			{ countries.length > 0 && (
				<div className="game__participating-countries">
					<span className="text-muted">Participating countries:</span>
					<ParticipatingCountries countries={ countries } />
				</div>
			) }
		</div>
	);
};

export default SummaryTop3;
