/**
 * External dependencies.
 */
import React, { Component, Fragment } from 'react';

/**
 * Internal dependencies.
 */
import Masthead from 'app/components/layout/masthead';
import Mastfoot from 'app/components/layout/mastfoot';

const withLayout = ( WrappedComponent ) => {
	return class extends Component {
		render() {
			const allProps = { ...this.state, ...this.props };

			return (
				<Fragment>
					<Masthead { ...allProps } />
					<WrappedComponent { ...allProps } />
					<Mastfoot { ...allProps } />
				</Fragment>
			);
		}
	};
};

export default withLayout;
