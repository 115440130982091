/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import CountryList from 'app/components/country-list';

const CountryDropdown = () => {
	const { currentCountry, currentCountryName } = hqbuff;

	return (
		<div id="masthead-country-dropdown" className="masthead-nav__country dropdown-container">
			<button id="country-toggle" className="country-toggle masthead-nav__toggle dropdown-toggle" data-nav="masthead-country-list">
				<img className="flag" src={ `/images/flag-${ currentCountry }.svg` } />
				<span className="country-toggle__country-fullname">{ currentCountryName }</span>
				<span className="country-toggle__country-shortname" aria-hidden="true">{ currentCountry.toUpperCase() }</span>
			</button>
			<nav id="masthead-country-list" className="masthead-nav__dropdown dropdown dropdown--right country-list">
				<CountryList />
			</nav>
		</div>
	);
};

export default CountryDropdown;
