/**
 * Internal dependencies.
 */
import TagArchiveListContainer from 'app/containers/tag-archive-list.js';
import { countryList } from 'utils';

const routes = [];

countryList.map( ( country ) => {
	routes.push( {
		path: `/${ country.short_name }/tag/words/:tag`,
		component: TagArchiveListContainer,
	} );
	routes.push( {
		path: `/${ country.short_name }/tag/trivia/:tag`,
		component: TagArchiveListContainer,
	} );
} );

export default routes;
