
/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withLayout } from 'app/hoc';

const Stats = ( props ) => {
	return (
		<div className="container site">
			<div className="primary-content">
				<h1>Stats</h1>
				<div id="total-shows-by-country" ></div>
				<div id="total-daily-players-timeseries" ></div>
				<div id="total-daily-prize-timeseries" ></div>
				<div id="total-daily-prize-awarded-timeseries" ></div>
			</div>
		</div>
	);
};

export default withLayout( Stats );
