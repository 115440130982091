/**
 * Internal dependencies.
 */
import { withPagination } from 'app/hoc';
import SavageQuestionList from 'app/layouts/savage-question-list';

const SavageQuestionListContainer = withPagination(
	SavageQuestionList,
	'savage-questions'
);

export default SavageQuestionListContainer;
