/**
 * External dependencies.
 */
import React from 'react';

const ParticipatingCountries = ( { countries } ) => (
	<ul>
		{ countries.map( ( country ) => (
			<li key={ country.code }>
				<span className="screen-reader-text">{ country.name }</span>
				<img className="flag" alt={ country.code } src={ `/images/flag-${ country.code }.svg` } />
			</li>
		) ) }
	</ul>
);

export default ParticipatingCountries;
