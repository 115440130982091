/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { getPercentEliminated } from 'app/components/game-types/trivia/utils.js';

const Question = ( props ) => {
	const { text, eliminated, advancing, direct_link: directLink, correct_answers: correctAnswers, incorrect_answers: incorrectAnswers } = props;

	return (
		<li key={ text }>
			<a href={ directLink }>{ text }</a><br />
			<span className="text-muted text-red">{ getPercentEliminated( incorrectAnswers, correctAnswers ) } eliminated</span>
		</li>
	);
};

export default Question;
