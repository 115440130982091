/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';

const Badge = ( props ) => {
	const { level, href, additionalClass, meta, children, onClick } = props;

	// Build class based on level and additional props.
	const className = classnames(
		'badge',
		additionalClass,
		{
			[ `badge--${ level || 'primary' }` ]: true,
		}
	);

	// Determine element type based on supplied `href`.
	const TagName = href ? 'a' : 'span';

	// Build a list of attributes, only adding no-null values.
	const attributes = {
		className,
		...( href && { href } ),
	};

	return (
		<TagName { ...attributes } onClick={ onClick }>
			{ children }

			{ meta && (
				<span className="badge__meta">{ meta }</span>
			) }
		</TagName>
	);
};

export default Badge;
