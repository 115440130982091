/* global hqbuff */

/**
 * External dependencies.
 */
import React, { Component } from 'react';
import ReactPopover from 'react-tiny-popover';

/**
 * Internal dependencies.
 */
import PopoverContent from './content.js';

class Popover extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			isOpen: this.props.isOpen,
		};

		this.toggleOpen = this.toggleOpen.bind( this );
		this.toggleClose = this.toggleClose.bind( this );
	}

	toggleOpen() {
		this.setState( {
			isOpen: true,
		} );
	}

	toggleClose() {
		this.setState( {
			isOpen: false,
		} );
	}

	render() {
		const { position, content, children } = this.props;
		const { isOpen } = this.state;

		return (
			<ReactPopover
				isOpen={ isOpen }
				position={ position || 'bottom' }
				containerStyle={ {
					overflow: 'visible',
				} }
				content={ <PopoverContent content={ content } /> }
				onClickOutside={ this.toggleClose }
			>
				<div onClick={ this.toggleOpen }>
					{ children }
				</div>
			</ReactPopover>
		);
	}
}

export default Popover;
