/**
 * External dependencies.
 */
import moment from 'moment';
import { groupBy, forEach, filter } from 'lodash';

/**
 * Generate a list of days grouped by year and month based
 * on a starting date.
 *
 * @param {Object|Date} startDate Start date.
 * @param {Object|Date} endDate End date.
 * @return {Array} Group of days.
 */
export const getArchiveDateList = ( startDate, endDate ) => {
	const allDays = new Array();
	const grouped = new Array();

	const momentEndDate = moment( endDate );
	momentEndDate.tz( hqbuff.country.timezone );

	for ( let d = startDate; d <= momentEndDate.endOf( 'day' ); d.setDate( d.getDate() + 1 ) ) {
		allDays.push( new Date( d ) );
	}

	const yearGroups = groupBy( allDays, ( date ) => {
		return moment( date ).year();
	} );

	forEach( yearGroups, ( days, year ) => {
		grouped[ year ] = new Array();

		const monthsGroups = groupBy( days, ( date ) => {
			return moment( date ).month();
		} );

		forEach( monthsGroups, ( daysInThisMonth, month ) => {
			grouped[ year ][ month ] = new Array();

			const daysInMonth = filter( daysInThisMonth, ( day ) => {
				return parseInt( moment( day ).month() ) === parseInt( month );
			} );

			forEach( daysInMonth, ( day ) => {
				grouped[ year ][ month ].push( day );
			} );
		} );
	} );

	return grouped;
};
