/* global hqbuff */

/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { withPagination } from 'app/hoc';
import Widget from 'app/components/widget';

import Question from './question.js';

const SavageQuestionList = ( props ) => {
	const { currentCountry } = hqbuff;
	const { items: rounds } = props;
	const title = 'Most Savage Questions';

	if ( ! rounds ) {
		return <Widget title={ title }><li>Loading...</li></Widget>;
	}

	if ( rounds && rounds.length === 0 ) {
		return null;
	}

	return (
		<Widget title={ title }>
			{ rounds.slice( 0, 10 ).map( ( round, i ) => (
				<Question key={ i } { ...round } />
			) ) }

			<a href={ `/${ currentCountry }/savage-questions` } className="widget__more"><strong>View More &rarr;</strong></a>
		</Widget>
	);
};

const SavageQuestions = withPagination(
	SavageQuestionList,
	'savage-questions'
);

export default SavageQuestions;
